.payroll_report-generator {
  margin-top: 0;
  h3 {
    font-weight: bold;
    font-size: 1.25rem;
  }

  .container {
    padding-top: 9.375rem;
    @media only screen and (max-width: 475px) {
      padding-top: 50px;
    }
  }
}
