.home_do {
  margin-top: 207px;
  margin-bottom: 220px;
  @media only screen and (max-width: 475px) {
    margin-top: 80px;
  }
  @media only screen and (max-width: 411px) {
    margin-bottom: 150px;
  }
  a {
    text-decoration: none;
  }
  h2 {
    color: $purple1;
    font-size: 1.875rem;
    font-weight: bold;
    padding-bottom: 1.87rem;
    line-height: 2.1875;
    margin: 0;
  }

  .grid-do {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    justify-items: center;
    justify-content: center;

    @media only screen and (max-width: 1100px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }

  .card-payroll {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    // width: 15.875rem;
    @media only screen and (max-width: 1100px) {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }
    @media only screen and (max-width: 576px) {
      grid-column: 1;
      grid-row: 1 / 3;
      margin-left: auto;
      margin-right: auto;
    }
  }
  // .card-cfdis {
  //   grid-column: 1 / 3;
  //   grid-row: 1;
  // }
  // .card-mycompany {
  //   grid-column: 3;
  //   grid-row: 1 / 3;
  // }
  // .card-dicoma {
  //   grid-column: 1;
  //   grid-row: 2;
  // }
  .card-software {
    @media only screen and (max-width: 1100px) {
      grid-column: 2 / 5;
      // grid-row: 3 / 4;
      // background-color: pink;
      // width: 100% !important;
      height: 100%;
    }
  }

  &__imgdo {
    background-image: radial-gradient(
      circle at bottom right,
      rgba(144, 254, 154, 1) 0%,
      rgba(0, 0, 0, 0) 14%,
      rgba(241, 247, 250, 0) 50%,
      rgba(241, 247, 250, 0) 100%
    );
    background-size: 400%;
    border-radius: 40px 100px 40px 100px !important;
    padding: 30px;
    max-width: 540px;
    max-height: 650px;
    position: relative;
    transition: background-position 350ms;
    cursor: pointer;
    @media only screen and (max-width: 475px) {
      border-radius: 40px;
    }
    &:hover {
      background-position: 100%;
    }

    .imgbox {
      position: absolute;
      top: 0;
      left: 0;
      background: url('../assets/img/img-do.jpg') no-repeat center center;
      background-size: cover;
      border-radius: 40px 100px 40px 100px;
      width: 100%;
      height: 100%;
      z-index: -1;
      @media only screen and (max-width: 475px) {
        border-radius: 40px;
      }
    }

    .loop--img {
      position: absolute;
      top: 287px;
      z-index: 10;
      @media only screen and (max-width: 475px) {
        top: 215px;
      }
    }

    p {
      margin: 353px 0 3.75rem 0;
      font-size: 1.875rem;
      color: $white;
      z-index: 10;
      line-height: 2.875rem;
      @media only screen and (max-width: 475px) {
        margin: 281px 0 3.75rem 0;
      }
      span {
        font-weight: bold;
      }
    }
    div {
      z-index: 10;
      img {
        position: absolute;
        bottom: 30px;
        right: 30px;
      }
    }
  }

  .card {
    padding: 30px 16px 27px 16px;
    border: none;
    background-image: radial-gradient(
      circle at bottom right,
      rgba(144, 254, 154, 1) 5%,
      rgba(241, 247, 250, 1) 20%,
      rgba(241, 247, 250, 0.6) 50%,
      #fff 100%
    );
    color: $purple;
    text-decoration: none;
    border-radius: 40px;
    min-width: 15.875rem;
    max-width: 100%;
    height: 100%;
    background-size: 250%;
    transition: background-position 350ms;
    h3 {
      font-size: 22px;
      font-weight: bold;
      margin: 30px 0 0 0;
      line-height: 35px;
    }
    p {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 20px;
      text-decoration: none;
    }
    &:hover {
      background-position: bottom right;
      cursor: pointer;
    }
  }

  .card-gear {
    background-image: radial-gradient(
      circle at bottom right,
      rgba(144, 254, 154, 1) 5%,
      rgba(241, 247, 250, 0) 20%,
      rgba(241, 247, 250, 0) 50%,
      rgba(250, 250, 250, 0) 100%
    );
    background-size: 250%;
    // width: 100% !important;
    transition: background-position 350ms;
    border: 2px dashed $purple1;
    p {
      margin-bottom: 53px;
    }

    &:hover {
      background-position: bottom right;
      cursor: pointer;
    }
  }

  .gap-card {
    gap: 1rem;
  }

  @media only screen and (max-width: 991px) {
    &__imgdo {
      background-position: center;
      border-radius: 40px;
      p {
        font-size: 1.375rem;
        span {
          font-weight: bold;
        }
      }
    }
  }
  @media only screen and (max-width: 576px) {
    .card {
      min-width: 18.75rem;
    }
    .gap-card {
      gap: 3.125rem;
    }

    &__imgdo {
      margin-bottom: 2rem;
      border-radius: 40px;
      p {
        font-size: 1.25rem;
      }
    }
  }
}
