.dicoma_consumption {
  background: rgb(246, 248, 248);
  background: linear-gradient(
    90deg,
    rgba(246, 248, 248, 1) 0%,
    rgba(246, 248, 248, 1) 50%,
    rgba(231, 235, 235, 1) 50%,
    rgba(231, 235, 235, 1) 100%
  );
  .col-my-company {
    padding: 80px 40px;
  }
  .hand {
    position: absolute;
    left: 0;
    top: -166px;
  }
  p {
    margin: 0;
  }
  h2 {
    font-size: 3.125rem;
    font-weight: 700;
    position: relative;

    @media only screen and (max-width: 475px) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      margin-top: 1rem;
      background: $purple;
      height: 4px;
      width: 65px;
      border-radius: 2.5rem;
      left: 0;

      @media only screen and (max-width: 475px) {
        margin-top: 1rem;
      }
    }
  }
  .description {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.625rem;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .my-company {
    background: $purple1;
    color: $white;
    font-size: 0.875rem;
    line-height: 1.18rem;
    padding: 15px;
    border-radius: 10px;
    max-width: 255px;

    a {
      color: inherit;
    }
  }

  @media only screen and (max-width: 1620px) {
    .hand {
      width: 700px;
      left: 0;
      top: -100px;
    }
  }
  @media only screen and (max-width: 1350px) {
    .hand {
      width: 600px;
      left: 0;
      top: -50px;
    }
  }
  @media only screen and (max-width: 1160px) {
    .hand {
      width: 500px;
      left: 0;
      top: 50px;
    }
  }
  @media only screen and (max-width: 991px) {
    background: $gray4;
    .hand {
      top: -25px;
    }

    .col-my-company {
      margin-top: 370px;
    }
  }
  @media only screen and (max-width: 600px) {
    background: $gray4;
    .hand {
      top: -50px;
    }
    .kiosko-title {
      position: absolute;
      top: 50px;
      left: 6%;
      width: 115px;
      line-height: 2rem;
    }
    .col-my-company {
      margin-top: 60%;
      padding: 80px 12px;
    }
  }

  @media only screen and (max-width: 599px) {
    .kiosko-title {
      position: absolute;
      top: 50px;
      width: 115px;
      line-height: 3rem;
    }
  }
  @media only screen and (max-width: 545px) {
    .kiosko-title {
      line-height: 3rem;
    }
  }
  @media only screen and (max-width: 475px) {
    .kiosko-title {
      line-height: 2rem;
    }
    .col-my-company {
      margin-top: 60%;
      padding: 80px 12px 50px 12px;
    }
  }
}
