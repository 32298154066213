.payroll_retrospective {
  margin-top: 0;

  &__info {
    color: $blue;
    line-height: 1.625rem;
  }

  .container {
    padding-top: 8.125rem;
    @media only screen and (max-width: 475px) {
      padding-top: 50px;
    }
  }
}

.sections-payroll section {
  padding-bottom: 9.375rem;
  margin-bottom: 0;
  margin-top: 0;
}
