.footer {
  display: block;
  background: $purple;
  color: $white;
  padding: 3.5625rem 0 2.5rem 0;
  text-align: left;
  font-size: 0.75rem;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    padding: 3.125rem 0;
  }

  .container {
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr 1fr 0.5fr;
    grid-template-rows: 5.125rem 13.4375rem 1.25rem;
    grid-template-areas:
      'logo links1 links2 links3 links4'
      'address links1 links2 links3 links4'
      'copyright links1 links2 links3 katana-logo';
    justify-items: start;

    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
      grid-template-areas:
        'logo'
        'address'
        'links4'
        'copyright'
        'katana-logo';
      justify-items: start;

      padding: 0 0.75rem;
    }
    @media only screen and (max-width: 475px) {
      padding: 0 3rem;
    }
  }

  a {
    color: $white;
    text-decoration: none;
  }

  .title {
    color: $purple2;
    text-transform: uppercase;
    @media only screen and (max-width: 768px) {
      color: $gray1;
      margin-top: 1.25rem;
    }
  }

  .telefono {
    /*margin-top: 50px;*/
  }

  .fw-regular {
    font-weight: 400;
  }

  .lang {
    cursor: pointer;
  }

  .unavailable {
    opacity: 0.3;
    color: lightgray !important;
  }

  &__logo {
    grid-area: logo;
  }

  &__address {
    grid-area: address;
    padding-right: 8rem;
    @media only screen and (max-width: 991px) {
      padding-right: 2rem;
    }
    @media only screen and (max-width: 768px) {
      padding: 1.875rem 0 0 0;
    }
  }

  &__copyright {
    grid-area: copyright;
    @media only screen and (max-width: 768px) {
      padding-bottom: 0.9375rem;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: start;
    @media only screen and (max-width: 768px) {
      /*display: inline-block;*/
    }

    .title:not(:first-child) {
      padding-top: 0.3125rem;
      @media only screen and (max-width: 768px) {
        padding-top: 0;
      }
    }

    & a,
    & div {
      margin-bottom: 0.625rem;
    }

    & div {
      cursor: default;
    }

    &.col1 {
      grid-area: links1;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    &.col2 {
      grid-area: links2;
      padding-right: 2rem;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    &.col3 {
      grid-area: links3;

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    &.col4 {
      grid-area: links4;
      @media only screen and (max-width: 768px) {
        div:not(.SM),
        a:not(.SM) {
          display: none;
        }
        div {
          color: #d3d9e8;
        }
      }
    }

    &__katana-logo {
      grid-area: katana-logo;
    }
  }
}

.rectangle {
  display: block;
  width: 100%;
  height: 5px;
  background: linear-gradient(#9a00ff 0%, #ff22bc 100%);

  @media only screen and (max-width: 768px) {
    display: block;
    max-width: 100%;
  }
}
