.contact_success {
  color: $purple1;
  line-height: 2.25rem;
  margin-top: 10.875rem;
  margin-bottom: 150px;
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .icon {
    width: 100px;
    height: 100px;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .description {
    width: 100%;
    text-align: center;
    font-size: 1.375rem;
    line-height: 36px;
    margin-top: 3.125rem;
    margin-bottom: 9.375rem;
  }
  span {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
}
