.page_approach_what {
  font-size: 1.875rem;
  line-height: 2.875rem;
  color: $purple;
  margin-top: 50px;
  margin-bottom: 63px;

  .question {
    font-weight: bold;
  }
  .our {
    color: $pink;
    font-weight: bold;
  }
}
