.payroll_services {
  color: $purple;
  font-size: 1.25rem;
  font-weight: bold;

  &--text-center {
    @media only screen and (max-width: 475px) {
      text-align: center;
    }
  }

  .container {
    padding-top: 5.625rem;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .margin {
    margin-top: 2.3125rem;
    padding-top: 1.25rem;
    margin-bottom: 0.9375rem;

    @media only screen and (max-width: 475px) {
      margin-top: 3.5625rem;
      margin-bottom: 3.125rem;
    }
  }

  .bold {
    font-weight: bold;
  }

  .wrapper {
    height: 680px;
    display: grid;
    grid-gap: 1.875rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-items: start;
    justify-items: center;

    @media only screen and (max-width: 1200px) {
      height: 900px;
      grid-gap: 1.2rem;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    @media only screen and (max-width: 991px) {
      height: 1500px;
      grid-gap: 1.2rem;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    @media only screen and (max-width: 786px) {
      height: 1700px;
      grid-gap: 1.2rem;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    @media only screen and (max-width: 503px) {
      height: 2500px;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(8, 1fr);
    }

    .card--big {
      grid-column: 1;
      grid-row: 1 / 3;
      height: calc(100% + 0.0875rem);
    }

    .card {
      height: 100%;
      padding: 1.25rem 1.25rem 0rem 1.25rem;
      background-image: radial-gradient(
        circle at bottom right,
        rgba(144, 254, 154, 1) 5%,
        rgba(241, 247, 250, 1) 20%,
        rgba(241, 247, 250, 0.6) 50%,
        #fff 100%
      );
      background-size: 250%;
      transition: background-position 350ms;
      border-radius: 2.5rem 2.5rem 2.5rem 6.25rem;
      border: none;
      position: relative;

      &__icons {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      &__title {
        padding-top: 1.25rem;
      }

      &__description {
        padding-top: 0.875rem;
        font-size: 1rem;
        font-weight: 400;

        &--gray {
          margin-top: 1.25rem;
          padding: 11px 8px 7px 8px;
          color: #73848d;
          font-weight: 400;
          font-size: 1rem;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2373848DFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          border-radius: 10px;
        }
      }

      &__update {
        font-size: 1rem;
        margin-top: 1.25rem;
      }

      &__arrow {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-top: 300px;
        padding: 0 1.25rem 1.75rem 1.25rem;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        border-radius: 1rem;
      }
      &:hover {
        background-position: bottom right;
        cursor: pointer;
      }
    }
  }
}
