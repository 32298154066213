.payroll_special-processes {
  margin-top: 0;

  .container {
    padding-top: 8.125rem;
    @media only screen and (max-width: 475px) {
      padding-top: 50px;
    }
  }

  ul,
  ol,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-left: 0.875rem;
  }

  .margin {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .cards {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
    justify-items: center;
    text-align: center;

    @media only screen and (max-width: 1124px) {
      grid-gap: 1.2rem;
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
    }

    @media only screen and (max-width: 511px) {
      display: block;
    }

    .card {
      border: none;
      font-size: 1rem;
      height: 100%;
      background: none;
      @media only screen and (max-width: 511px) {
        margin-bottom: 50px;
      }

      .card-header {
        color: $white;
        border-radius: 1.25rem 1.25rem 0 0;

        &__icon {
          padding: 35px 0 20px 0;
        }

        &__title {
          font-weight: bold;
          font-size: 1.25rem;
          padding-bottom: 3.5rem;
        }
      }
      .card-body {
        color: $purple;
        background: linear-gradient(#fff 0%, #f1f7fa 100%);
        box-shadow: 0px 3px 35px rgba(0, 0, 0, 0.16);
        border-radius: 1.25rem;
        margin-top: -2.25rem;
        padding: 1.875rem 1.25rem;
        text-align: left;
        z-index: 3;
        line-height: 1.625rem;
        font-size: 1rem;
      }
    }
  }
}
