.home_clients {
  margin-top: 150px;
  margin-bottom: 150px;
  p {
    color: $purple;
    font-size: 1.875rem;
    line-height: 3.125rem;
    margin-bottom: 5.625rem;
  }
  &__layout {
    display: flex;
    gap: 3.75rem;
  }

  $animationSpeed: 50s;

  // Animation
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-220px * 16));
    }
  }

  // Styling
  .slider {
    background: white;
    height: 140px;
    margin: 90px auto auto auto;
    overflow: hidden;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 478px) {
      margin-bottom: 85px;
    }

    .slide-track {
      animation: scroll $animationSpeed linear infinite;
      display: flex;
      width: calc(220px * 32);
    }

    .slide {
      height: 140px;
      width: 220px;
    }
  }
}