.dicoma_benefits {
  @media only screen and (max-width: 475px) {
    margin-top: 6rem;
  }
  h3 {
    font-size: 24px;
  }
  .title {
    line-height: 60px;
    font-size: 3.125rem;
    width: 55%;
    position: relative;
    margin-bottom: 4.375rem;
    font-weight: bold;

    @media only screen and (max-width: 475px) {
      font-size: 1.875rem;
      line-height: 50px;
      margin-bottom: 5.375rem;
      width: 100%;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      margin-top: 1.675rem;
      background: $blue1;
      height: 4px;
      width: 65px;
      border-radius: 2.5rem;
      left: 0;
    }
  }

  .wrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 520px;
    gap: 1.875rem;

    @media only screen and (max-width: 1124px) {
      max-height: 620px;
    }
    @media only screen and (max-width: 992px) {
      max-height: 100%;
    }

    .item {
      width: 50%;

      @media only screen and (max-width: 992px) {
        width: 100%;
      }

      &__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &--icon {
          margin-right: 0.625rem;
          @media only screen and (max-width: 475px) {
            margin-right: 0.9375rem;
          }
        }
      }
    }
  }
}
