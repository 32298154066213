.dicoma_general {
  margin-bottom: 100px;
  @media only screen and (max-width: 475px) {
    margin-top: 79px;
  }

  .img-computer {
    max-width: 1304px;
    margin: 0 auto;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }
  h2 {
    margin-top: 50px;
    margin-bottom: 67px;
    font-size: 3.125rem;
    line-height: 3.5625rem;
    font-weight: bold;
    position: relative;

    @media only screen and (max-width: 475px) {
      font-size: 1.875rem;
      line-height: 3.125rem;
      margin-top: 2.45rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      margin-top: 1.675rem;
      background: $purple;
      height: 4px;
      width: 65px;
      border-radius: 2.5rem;
      left: 0;

      @media only screen and (max-width: 475px) {
        margin-top: 1rem;
      }
    }
  }
  p {
    margin-bottom: 90px;
    font-size: 1.25rem;
    font-weight: 400 !important;
    line-height: 2.25rem;
    font-weight: 300;

    @media only screen and (max-width: 475px) {
      margin-bottom: 30px;
    }
  }
  .img-people {
    max-width: 464px;
    margin: 0 auto;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }
}
