.integrity_usability {
  .container {
    margin-top: 0;
    margin-bottom: 0;
  }
  h2 {
    font-size: 3.125rem;
    font-weight: bold;
    line-height: 3.75rem;
    margin-bottom: 1rem;
    @media only screen and (max-width: 475px) {
      font-size: 30px;
      line-height: 50px;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 2.25rem;
  }

  .img-line {
    margin-bottom: 50px;
  }

  .description {
    margin-bottom: 5.0625rem;
  }
  .img-laptop {
    margin: 0 auto;
    max-width: 880px;
  }
  .detail {
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 2.25rem;
    margin-bottom: 50px;
  }
  @media (max-width: 475px) {
    .button-nomi div {
      width: 100% !important;
    }
  }
}
