.button-nomi {
  text-decoration: none;

  a {
    text-decoration: none;
  }
  .btn-comp {
    text-align: center;

    background-image: linear-gradient(to right, $green 50%, #c9ff14 100%);
    font-weight: bold;
    color: $purple !important;
    border-radius: 10em;
    border: none;
    padding: 0.596rem 1.625rem;
    cursor: pointer;
    font-size: 1.25rem;
    background-size: 250%;
    transition: background-position 350ms;

    &:hover,
    &:active {
      background-position: right;
    }

    span {
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: 475px) {
    display: flex;
    justify-content: center;
    text-align: center;
    div {
      padding: 0.596rem 1.625rem !important;
      width: 100% !important;
    }

    a.button-nomi.btn {
      display: block;
    }
    button.btn-comp {
      width: 100%;
    }
  }
}

a.button-nomi.btn {
  padding: 0 !important;
}
