.cfdis_personalization {
  margin-top: 280px;
  @media only screen and (max-width: 475px) {
    margin-top: 130px;
  }

  h2 {
    margin-bottom: 0;
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 2.25rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 2.25rem;
    margin-bottom: 0;
  }

  .img-desktop {
    display: inline-block;
    @media only screen and (max-width: 475px) {
      display: none;
    }
  }

  .img-mobile {
    display: none;

    @media only screen and (max-width: 475px) {
      display: inline-block;
      margin-top: 28px;
    }
  }

  .info-payroll-mobile {
    display: none;

    color: $gray3;
    font-size: 1rem;
    line-height: 1.625rem;

    @media only screen and (max-width: 475px) {
      display: block;
      margin-top: 28px;
    }

    .info {
      margin-bottom: 30px;

      &__title {
        font-weight: bold;
        font-size: 1.25rem;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      &__description {
        // margin: ;
      }
    }
  }
}
