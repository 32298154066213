.dicoma_application {
  position: relative;

  @media only screen and (max-width: 475px) {
    margin-top: 8.3125rem;
  }

  &__info {
    padding-top: 4.375rem;
    padding-right: 17.625rem;
    padding-bottom: 6.875rem;
    padding-left: 0;

    @media only screen and (max-width: 1200px) {
      padding-right: 15.625rem;
    }

    @media only screen and (max-width: 992px) {
      padding-right: 0;
    }

    @media only screen and (max-width: 768px) {
      padding-top: 2.5rem;
      padding-bottom: 6rem;
    }

    @media only screen and (max-width: 578px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    @media only screen and (max-width: 398px) {
      padding-top: 1rem;
      padding-bottom: 2rem;
    }

    .description {
      color: $white;
      line-height: 3.75rem;
      font-weight: bold;
      font-size: 3.125rem;
      margin-bottom: 3.125rem;

      @media only screen and (max-width: 578px) {
        font-size: 2.5rem;
      }
    }

    .btn-dicoma {
      background-image: linear-gradient(
        120deg,
        $yellow 0%,
        $yellow 50%,
        HSL(60, 100%, 60%) 55%,
        HSL(60, 100%, 60%) 80%,
        HSL(280, 100%, 80%) 100%
      );
      background-size: 350%;
      transition: background-position 350ms;
      border-radius: 6.875rem;
      font-size: 1.25rem;
      width: 320px;
      font-weight: bold;
      color: $black;
      padding: 0.8125rem 2.1875rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      cursor: pointer;
      text-decoration: none;

      @media only screen and (max-width: 578px) {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
        div {
          padding: 0.596rem 1.625rem;
          max-width: 475px;
        }
      }

      &:hover,
      &:active {
        background-position: right;
      }

      .icon {
        margin-right: 0.625rem;
      }
    }
  }

  &__bg {
    position: absolute;
    z-index: -2;
    max-height: 500px;
    max-width: 1400px;
    background: $blue1;
    inset: 0;
    margin: -2.5rem auto -2rem auto;
    border-radius: 2.5rem;
    overflow: hidden;

    @media only screen and (max-width: 991px) {
      max-height: 550px;
      background: $blue1 center url(../assets/img/application-tablet.svg);
    }

    @media only screen and (max-width: 578px) {
      background: $blue1;
      border-radius: 0;
    }

    @media only screen and (max-width: 398px) {
      max-height: 600px;
    }
    @media only screen and (max-width: 384px) {
      max-height: 650px;
    }
  }

  &__bg-img {
    z-index: -2;
    position: absolute;
    inset: 0;
    margin: -14.5rem auto auto auto;
    right: -20px;

    @media only screen and (max-width: 1200px) {
      margin-top: -12rem;
    }
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
}
