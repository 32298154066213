.approach_steps {
  display: flex;
  justify-content: center;
  color: $purple1;
  padding: 12px;
  margin-top: 0;
  h3 {
    font-weight: 700 !important;
    font-size: 1.875rem;
    margin-bottom: 1.25rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 36px;
  }

  &__wrapper {
    max-width: 1180px;
    margin: 0 auto;
    height: auto;
    position: relative;
    @media only screen and (max-width: 1200px) {
      max-width: 100%;
    }
    &--steps {
      width: 100%;
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translateX(-50%);
      z-index: -2;
      border-radius: 40px 40px 0px 0px;
      background: linear-gradient(to bottom, #f1f7fa 0%, rgba(241, 247, 250, 0) 100%);
      @media only screen and (max-width: 475px) {
        max-width: 93%;
      }
    }
  }
  &__1 {
    padding-top: 100px;
    margin-bottom: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      font-weight: 700 !important;
      font-size: 1.875rem;
      margin-bottom: 1.25rem;
    }
    p {
      font-size: 1.25rem;
      line-height: 36px;
    }
    &--img-right {
      margin-left: 120px;
      margin-right: 60px;
      @media only screen and (max-width: 992px) {
        margin-left: 40px;
        margin-right: 0;
      }
      @media only screen and (max-width: 769px) {
        margin: 40px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    @media only screen and (max-width: 769px) {
      display: block;
      margin-bottom: 100px;
    }
  }
  &__2 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    position: relative;
    h3 {
      font-weight: 700 !important;
      font-size: 1.875rem;
      margin-bottom: 1.25rem;
    }
    p {
      font-size: 1.25rem;
      line-height: 36px;
    }
    &--img-left {
      margin-left: 60px;
      margin-right: 120px;
      @media only screen and (max-width: 992px) {
        margin-left: 0;
        margin-right: 60px;
      }
      @media only screen and (max-width: 769px) {
        margin: 40px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    @media only screen and (max-width: 769px) {
      display: block;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      max-height: 474px;
      pointer-events: none;
      inset: 0;
      @media only screen and (max-width: 1024px) {
        width: 60%;
      }
      @media only screen and (max-width: 992px) {
        width: 70%;
      }
      @media only screen and (max-width: 769px) {
        display: none;
      }
    }

    &::before {
      background: no-repeat center url('../assets/img/dots-up.svg');
      margin: -40% auto auto auto;
      @media only screen and (max-width: 1024px) {
        margin: -50% auto auto auto;
        background-size: contain;
      }
      @media only screen and (max-width: 992px) {
        margin: -60% auto auto auto;
      }
    }
    &::after {
      background: no-repeat center url('../assets/img/dots-down.svg');
      margin: auto auto -40% auto;
      @media only screen and (max-width: 1024px) {
        margin: auto auto -50% auto;
        background-size: contain;
      }
      @media only screen and (max-width: 992px) {
        margin: auto auto -60% auto;
      }
    }
  }
  &__3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 450px;
    h3 {
      font-weight: 700 !important;
      font-size: 1.875rem;
      margin-bottom: 1.25rem;
    }
    p {
      font-size: 1.25rem;
      line-height: 36px;
    }
    &--img-right {
      margin-left: 120px;
      margin-right: 60px;
      @media only screen and (max-width: 992px) {
        margin-left: 40px;
        margin-right: 0;
      }
      @media only screen and (max-width: 769px) {
        margin: 40px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    @media only screen and (max-width: 769px) {
      display: block;
      margin-top: 100px;
    }
  }

  @media only screen and (max-width: 1024px) {
    &__1 {
      padding-top: 100px;
      margin-bottom: 450px;
    }
  }
  @media only screen and (max-width: 991px) {
    &__wrapper {
      width: 991px;
    }
    &__1 {
      margin-bottom: 300px;
    }
    &__2 {
      margin-bottom: 300px;
    }
  }
  @media only screen and (max-width: 768px) {
    p {
      width: 100%;
    }

    &__1 {
      margin-bottom: 100px;
    }
    &__2 {
      margin-bottom: 100px;
    }
  }
}
