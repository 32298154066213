.the-company_our-approach {
  color: $purple;
  display: block;
  margin: 0 auto 150px auto;
  overflow-x: hidden;
  background: linear-gradient(to right, #f1f7fa 55%, rgba(250, 250, 250, 0) 60%);

  .container {
    margin: 0 auto;
    max-width: 100%;
    background-color: $gray1;

    &__bg {
      background: no-repeat right top url('../assets/img/our-approach.png');
      border-radius: 0 1.5rem 7rem 0;
      @media only screen and (max-width: 768px) {
        border-radius: 0;
        background: no-repeat right top url('../assets/img/our-approach-tablet.png');
      }
    }
  }

  .card {
    padding: 2rem 0;
    background: transparent;
    border: none;

    &-title {
      font-weight: bold;
      font-size: 1.875rem;
    }

    &-text {
      padding-top: 2em;
      font-size: medium;
      font-size: 1.25rem;
      padding-bottom: 2rem;
      line-height: 2.25rem;

      span {
        font-weight: bold;
      }

      @media only screen and (max-width: 768px) {
        padding-right: 18rem;
      }
    }

    &__icon {
      &--team-nomi {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.the-company_our-approach_mobile {
  color: $purple;
  display: block;
  max-width: 475px;
  margin: 4px 0;
  overflow-x: hidden;

  .container {
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__bg {
      background: no-repeat center url('../assets/img/our-approach-mobile.png');
      object-fit: cover;
      height: 100%;
    }
  }

  .card-text {
    padding: 2rem 0;
    font-size: medium;
    font-size: 1.25rem;
    line-height: 2.25rem;

    span {
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 475px) {
    .btn-comp {
      width: 100%;
      @media only screen and (max-width: 359px) {
        font-size: 1rem;
        span img {
          margin-left: 0;
          max-width: 15px !important;
        }
      }
    }
  }
}
