.the-company_our-motivation {
  color: $purple;
  margin-top: 150px;
  margin-bottom: 150px;
  position: relative;
  .h2 {
    margin: 0;
  }

  .container {
    display: grid;
    margin: 0 auto;
    position: relative;
    @media (min-width: 992px) {
      grid-auto-flow: column;
      grid-auto-columns: 2fr 3fr;
    }
    @media (min-width: 1124px) {
      grid-auto-columns: 1fr;
    }
  }

  .info {
    &__title {
      font-family: 'Gloria Hallelujah', cursive;
      font-size: 1.75rem;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    &__text {
      font-size: 1.25rem;
      line-height: 2.25rem;
      margin-bottom: 0px;
    }
  }

  .illustration {
    display: grid;
    align-content: center;
    position: relative;
    padding-top: 50px;
    &__circles {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @media only screen and (max-width: 991px) {
        justify-content: space-evenly;
      }
      @media only screen and (max-width: 768px) {
        align-items: space-between;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
      }
      strong {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.75rem;
        text-transform: uppercase;
        line-height: 1rem;
        color: white;
        text-align: center;
        z-index: 5000;
        background: $purple;
        border-radius: 5rem;
        width: 98px;
        height: 98px;
      }
      &--arrow {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 13px;
          height: 6px;
          background-color: $purple;

          right: -18%;
          top: 46%;
          @media only screen and (max-width: 991px) {
            right: -25%;
          }
          @media only screen and (max-width: 768px) {
            width: 6px;
            height: 13px;
            right: 46%;
            top: 107%;
          }
        }
        &::after {
          --size: 0.8em;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-radius: 0.25rem;

          right: -29%;
          top: 39.5%;

          border-left: 14px solid $purple;
          border-bottom: var(--size) solid transparent;
          border-top: var(--size) solid transparent;

          @media only screen and (max-width: 991px) {
            right: -35%;
          }
          @media only screen and (max-width: 768px) {
            right: 39%;
            top: 117%;

            border-left: var(--size) solid transparent;
            border-right: var(--size) solid transparent;
            border-bottom: unset;
            border-top: 14px solid $purple;
          }
        }
      }
      &--equal {
        position: relative;
        span {
          &::before,
          &::after {
            content: '';
            position: absolute;
            width: 15px;
            height: 4px;
            background-color: $purple;
            margin: -0.4rem 0 0 -0.75rem;
          }
          &::after {
            margin-top: 0.1rem;
          }
        }
      }
      .mexico {
        max-width: 124px;
      }
    }

    &__better-country {
      font-family: 'Gloria Hallelujah', cursive;
      font-size: 1.75rem;
      font-weight: 400;
      line-height: 2.25rem;
      text-align: center;
      justify-self: right;
      &--mobile {
        display: none;
      }
      @media only screen and (max-width: 991px) {
        margin-right: 2.8rem;
      }
      @media only screen and (max-width: 768px) {
        justify-self: center;
        margin-right: 0;
        margin-top: 1rem;
        &--desktop {
          display: none;
        }
        &--mobile {
          display: block;
        }
      }
    }
  }

  &__bg {
    position: absolute;
    z-index: -2;
    max-height: 300px;
    max-width: 1180px;
    background: $gray2;
    inset: 0;
    margin: -2.5rem auto -2rem auto;
    border-radius: 2.5rem;
    overflow: hidden;

    @media only screen and (max-width: 991px) {
      max-height: 410px;
    }
    @media only screen and (max-width: 768px) {
      border-radius: 0;
      max-height: calc(100% + 100px);
    }
  }
}
