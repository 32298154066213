.payroll_communication {
  margin-top: 0;
  margin-bottom: 230px;

  .container {
    padding-top: 8.125rem;
  }

  h3 {
    font-weight: bold;
    font-size: 1.25rem;
  }
  li {
    max-width: 468px;
    font-weight: bold;
  }

  .communication-img {
    max-width: 555px;
    margin: 0 auto;
    @media only screen and (max-width: 991px) {
      margin-top: 30px;
      max-width: 100%;
    }
  }
}
