.cfdis_title {
  padding-top: 20px;

  .cfdis_description {
    line-height: 2.25rem;
    p {
      margin: 0;
    }
    font-size: 1.25rem;
    li {
      margin: 0;
    }
  }
}
