.my_company_accessibility {
  h2 {
    font-size: 3.125rem;
    font-weight: bold;
    line-height: 3.75rem;
    margin-bottom: 1rem;
    @media only screen and (max-width: 475px) {
      font-size: 30px;
      line-height: 50px;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 2.25rem;
    margin-bottom: 50px;
  }

  .img-line {
    margin-bottom: 50px;
  }

  .description {
    margin-bottom: 6.25rem;
  }

  .img-devices {
    max-width: 1144px;
    margin: 0 auto;
    padding-right: 0.5rem;
    @media only screen and (max-width: 992px) {
      width: 100%;
      padding-right: 0;
    }
  }
}
