.approach_our {
  color: $purple;
  margin-top: 0;
  margin-bottom: 100px;
  h2 {
    font-weight: bold;
    line-height: 2.5rem;
    font-size: 1.875rem;
    text-align: center;
    margin-bottom: 80px;
  }
  .card {
    border: none;
    height: 26.25rem;
    border-radius: 0px 0px 40px 40px;
    background: linear-gradient(rgba(241, 247, 250, 0) 0%, $gray2 100%);
    .card-body-payrroll {
      padding: 2.375rem 10px 3.125rem 10px !important;
      img {
        margin-bottom: 74px;
      }
    }
    .card-body-sofware {
      padding: 0.3125rem 10px 3.125rem 10px;
      img {
        margin-bottom: 42px;
      }
    }
  }
}
