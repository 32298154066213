.payroll_incidents {
  margin-top: 0;
  margin-bottom: 0;
  @media only screen and (max-width: 475px) {
    padding-bottom: 100px !important;
  }

  .container {
    padding-top: 8.125rem;
    @media only screen and (max-width: 475px) {
      padding-top: 50px;
    }
  }
  .mt-5 {
    margin-top: 100px !important;
  }
  h2 {
    @media only screen and (max-width: 475px) {
      margin-bottom: 50px;
    }
  }
  .wrapper_incidents {
    display: flex;
    justify-content: space-between;
    margin-top: 105px;

    .assistance-control {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      position: relative;

      h2 {
        font-weight: bold;
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 0;
        @media only screen and (max-width: 1124px) {
          position: relative;
        }
      }
      p {
        margin: 0;
      }

      .left-card {
        background-color: white;
        padding: 20px 20px 10px 20px;
        border: 1px dashed $purple;
        border-radius: 10px;
        max-width: 218px;
        position: relative;
        &::before,
        &::after {
          content: '';
          position: absolute;
          inset: 0;
          z-index: 20;
        }
        &::before {
          margin: auto -2.5rem auto auto;
          width: 40px;
          height: 3px;
          border-bottom: 1px dashed $purple;
        }
        &::after {
          margin: auto -3.5rem auto auto;
          border: 2px solid white;
          border-radius: 20px;
          box-sizing: content-box;
          width: 10px;
          height: 10px;
          background-color: $purple;
        }
        @media only screen and (max-width: 1124px) {
          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
    .features {
      align-self: stretch;
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;

      .right-card {
        background-color: white;
        padding: 20px 20px 10px 20px;
        border: 1px dashed $purple;
        border-radius: 10px;
        max-width: 230px;
        position: relative;
        &::before,
        &::after {
          content: '';
          position: absolute;
          inset: 0;
          z-index: 20;
        }
        &::before {
          margin: auto auto auto -3.9rem;
          width: 60px;
          height: 3px;
          border-bottom: 1px dashed $purple;
        }
        &::after {
          margin: auto auto auto -4.75rem;
          border: 2px solid white;
          border-radius: 20px;
          box-sizing: content-box;
          width: 10px;
          height: 10px;
          background-color: $purple;
        }
        @media only screen and (max-width: 1124px) {
          &::before,
          &::after {
            display: none;
          }
        }
      }
      .right-card2 {
        background-color: white;
        padding: 20px 20px 10px 20px;
        border: 1px dashed $purple;
        border-radius: 10px;
        max-width: 230px;
        position: relative;
        &::before,
        &::after {
          content: '';
          position: absolute;
          inset: 0;
          z-index: 20;
        }
        &::before {
          margin: auto auto auto -9rem;
          width: 143px;
          height: 3px;
          border-bottom: 1px dashed $purple;
        }
        &::after {
          margin: auto auto auto -10.5rem;
          border: 2px solid white;
          border-radius: 20px;
          box-sizing: content-box;
          width: 10px;
          height: 10px;
          background-color: $purple;
        }
        @media only screen and (max-width: 1124px) {
          &::before,
          &::after {
            display: none;
          }
        }
      }

      .upper {
      }
      .lower {
        margin-bottom: -1.35rem;
      }
    }
  }
  .integrity-desktop {
    display: flex;
    gap: 1rem;
    margin-top: 96px;
    h3 {
      font-size: 20px;
      font-weight: bold;
      line-height: 36px;
    }
    p {
      font-size: 1rem;
      line-height: 26px;
    }
  }
  .cards-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
    @media only screen and (min-width: 767px) {
      justify-content: stretch;
    }

    .card {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      background: none;
      border: 1px dashed $purple;
      padding: 20px;
      border-radius: 10px;
      max-width: 100%;
      @media only screen and (min-width: 767px) {
        width: 100%;
      }

      &:not(:first-child) {
        margin-top: 50px;
      }
      &__body {
        &--title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;
          .icon {
            margin-right: 10px;
          }
        }
      }
      h3 {
        font-weight: bold;
        font-size: 1rem;
        margin: 0;
      }
      p {
        font-size: 1.142rem;
        margin: 0;
      }
      .ps-4 {
        padding-left: 1.9rem !important;
      }
    }
  }
  .integrity {
    margin-top: 50px;
    color: #aa20ff;
    h4 {
      margin: 0;
      font-size: 1rem;
      font-weight: bold;
    }
    &--img {
      margin: 20px 0;
    }
    h3 {
      font-size: 20px;
      line-height: 36px;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }
}
