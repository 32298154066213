body {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  color: $purple;
}

section {
  margin-top: 10rem;
  margin-bottom: 10rem;
  position: relative;

  @media (max-width: 768px) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  @media (max-width: 475px) {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

.gloria {
  font-family: 'Gloria Hallelujah', cursive;
}

.link {
  text-decoration: none;
  color: $purple;
  &:hover {
    color: $purple;
  }
}

.hide-on-desktop {
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
}

.hide-on-mobile {
  display: none;
  @media only screen and (min-width: 769px) {
    display: block;
  }
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #4b2790;
}
.carousel-indicators [data-bs-target] {
  border-radius: 40px;
  background-clip: unset;
  border-bottom: unset;
  border-top: unset;
  width: 15px;
  height: 15px;
}
.carousel-item {
  min-height: 600px;
}

// Modificamos el container de bootstrap.

@media (min-width: 1124px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1080px !important;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 475px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1080px !important;
    padding-left: 48px;
    padding-right: 47px;
  }
}
