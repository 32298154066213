.software_production {
  margin-top: 0;

  .container {
    padding-top: 9.375rem;
    padding-bottom: 4.75rem;
  }

  .margin {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .cards {
    display: grid;
    grid-gap: 1.125rem;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    justify-items: center;
    text-align: left;

    @media only screen and (max-width: 1200px) {
      grid-gap: 1.5rem;
    }

    @media only screen and (max-width: 991px) {
      grid-gap: 1.2rem;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
    }

    @media only screen and (max-width: 768px) {
      grid-gap: 3.125rem;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }

    .card {
      border: none;
      border-radius: 2.5rem 2.5rem 0.625rem 2.5rem;
      font-size: 1.25rem;
      height: 100%;
      background: $gray4;
      padding: 1.875rem 2.125rem;

      &__title {
        font-weight: bold;
        font-size: 1.25rem;
        margin: 0 0 0.625rem 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.25rem;
      }

      &__text {
        line-height: 2.25rem;
      }
    }
  }

  .line {
    width: 100%;
    height: 2px;
    background: $purple;
    @media only screen and (max-width: 475px) {
      display: none;
    }
  }
}
