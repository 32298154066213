.home_how {
  color: $white;
  display: block;
  margin: 0 0 8rem 0;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;

  a {
    text-decoration: none;
  }

  .container {
    margin: 0 auto;
    background: linear-gradient(to right, #4b2192 65%, rgba(255, 255, 255, 0) 100%);
    border-radius: 30px 30px 30px 80px;

    @media only screen and (max-width: 991px) {
      background: no-repeat 30% url(../assets/img/how-people-tablet.jpg);
      background-size: cover;
    }
    @media only screen and (max-width: 578px) {
      background: rgba(255, 255, 255, 0);
      border-radius: 0;
    }
  }

  &__info {
    padding: 77px 0px 90px 61px;
    font-size: 30px;
    width: 70%;

    @media only screen and (max-width: 991px) {
      padding: 77px 61px;
      width: 100%;
    }
    @media only screen and (max-width: 768px) {
      text-align: center;
    }
    @media only screen and (max-width: 578px) {
      padding: 77px 0;
      text-align: left;
    }
    @media only screen and (max-width: 359px) {
      // padding: 70px 0;
      width: 100%;
    }

    .title {
      font-weight: bold;
      @media only screen and (max-width: 375px) {
        font-size: 24px;
      }
    }
    .description {
      font-weight: 400;
      margin-top: 50px;
      margin-bottom: 50px;
      @media only screen and (max-width: 578px) {
        margin-top: 30px;
        margin-bottom: 80px;
      }
      @media only screen and (max-width: 375px) {
        margin-top: 30px;
        margin-bottom: 50px;
        font-size: 24px;
      }
    }
    .btn-desktop {
      display: inline-block;
      padding: 0;
      @media only screen and (max-width: 475px) {
        display: none;
      }
    }
    .btn-mobile {
      display: none;
      @media only screen and (max-width: 475px) {
        display: block;
        padding: 0.375rem 0;
      }
      @media only screen and (max-width: 359px) {
        font-size: 1rem;
      }
    }

    .btn-comp {
      width: 100%;
      @media only screen and (max-width: 359px) {
        font-size: 1rem;
        span img {
          margin-left: 0;
          max-width: 15px !important;
        }
      }
    }
  }

  &__bg-img {
    z-index: -1;
    position: absolute;
    inset: 0;
    margin: auto;
    right: -970px;
    max-height: 520px;
    border-radius: 30px 30px 30px 80px;

    @media only screen and (max-width: 1124px) {
      max-height: 550px;
    }
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  &__bg-mobile-img {
    display: none;
    @media only screen and (max-width: 578px) {
      display: block;
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
    }
  }
}
