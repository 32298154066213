.contact_talent {
  color: $purple1;
  line-height: 2.25rem;
  margin-top: 0;
  margin-bottom: 150px;

  h3 {
    width: 100%;
    text-align: center;
    font-size: 1.375rem;
    font-weight: bold;
  }

  .description {
    width: 100%;
    text-align: center;
    font-size: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 3.25rem;
  }
  .wrapper {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top, #fff 0%, #f1f7fa 100%);
    border-radius: 2.5rem;
    padding: 4.25rem 2.5rem 3.125rem 2.5rem;
    margin-top: 7.5rem;
    @media only screen and (max-width: 411px) {
      background: none;
      padding: 4.25rem 0 3.125rem 0;
    }
    &__bg--mobile {
      display: none;
      @media only screen and (max-width: 475px) {
        display: block;
        position: absolute;
        inset: 0;
        z-index: -1;
        margin: 9.75rem 1.75rem 0 1.75rem;
        background: linear-gradient(to top, #fff 0%, #f1f7fa 100%);
        border-radius: 2.5rem;
      }
    }
    .icon {
      position: absolute;
      top: 0;
      margin-top: -3.5rem;
    }

    .form {
      position: relative;
      display: grid;
      grid-gap: 3.125rem 5rem;
      grid-template-columns: repeat(6, minmax(10px, 1fr));
      padding-bottom: 3.125rem;
      @media only screen and (max-width: 1124px) {
        grid-gap: 1.5625rem 2.5rem;
      }
      @media only screen and (max-width: 992px) {
        display: block;
        width: 100%;
      }

      &_group {
        display: flex;
        flex-flow: column wrap;
        grid-column: 1 / 2;
        @media only screen and (max-width: 992px) {
          margin-bottom: 3.125rem;
        }
        &--label {
          font-size: 1rem;
          font-weight: bold;
          position: relative;
        }
      }
      &__dropdown-menu {
        position: relative;
        width: 100%;

        select {
          font-size: 0.875rem;
          font-weight: 400;
          padding: 0.6875rem 1.25rem;
          color: $purple1;
          background: #e2eaef;
          border: none;
          border-radius: 2.5rem;
          cursor: pointer;
          width: 100%;
        }
        .select--variation {
          background: #e5e3ff;
        }

        .custom-select {
          position: relative;
        }

        .custom-arrow {
          display: block;
          top: 20%;
          bottom: 20%;
          right: 1.7%;
          background: #e2eaef;
          // background: red;
          border-radius: 5rem;
          height: 60%;
          width: 2rem;
          position: absolute;
          pointer-events: none;
          @media only screen and (max-width: 992px) {
            right: 0;
          }

          &::after {
            --size: 0.45em;
            content: '';
            position: absolute;
            width: 0;
            height: 0;

            left: 0;
            top: 40%;

            border-left: var(--size) solid transparent;
            border-right: var(--size) solid transparent;
            border-top: var(--size) solid $purple1;
          }
        }
        .custom-arrow--variation {
          background: #e5e3ff;
        }
      }
      .upload-btn {
        display: flex;
        align-items: center;
        #custom-button {
          // width: 100px;
          height: 40px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          color: white;
          font-weight: bold;
          font-size: 14px;
          background-color: #3c87ff;
          border: 1px solid #000;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 200ms;
          &:hover {
            background-color: #206be4;
          }
        }
        #custom-text {
          margin-left: 10px;
          color: #aaa;
          border: 1px solid #777;
          width: 170px;
          height: 40px;
          background-color: #fff;
          line-height: 1rem;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 10px;
          @media only screen and (max-width: 992px) {
            width: 100%;
            overflow: hidden;
          }
          @media only screen and (max-width: 768px) {
            width: 73%;
          }
          @media only screen and (max-width: 576px) {
            width: 170px;
          }
        }
      }
      .column--1 {
        grid-column: 1 / 3;
      }
      .column--2 {
        grid-column: 3 / 5;
      }
      .column--3 {
        grid-column: 5 / 7;
      }
      .comments--1,
      .comments--2,
      .comments--3 {
        textarea {
          resize: none;
          padding: 0.625rem;
        }
        ::placeholder {
          color: #8fa2ac;
          font-size: 1rem;
          font-style: italic;
        }
      }
      .comments--1 {
        grid-column: 1 / 4;
      }
      .comments--2 {
        grid-column: 4 / 7;
      }
      .comments--3 {
        grid-column: 1 / 7;
        margin-bottom: 3.125rem;
        // textarea {
        //   min-height: 80px;
        // }
      }

      .btn-form {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        text-align: center;
        width: 320px;

        background-image: linear-gradient(to right, $green 50%, #c9ff14 100%);
        font-weight: bold;
        color: $purple !important;
        border-radius: 10em;
        border: none;
        padding: 0.596rem 1.625rem;
        cursor: pointer;
        font-size: 1.25rem;
        background-size: 250%;
        transition: background-position 350ms;

        &:hover,
        &:active {
          background-position: right;
        }
      }
    }
  }
}
