.the-company_our-culture {
  color: $purple;
  font-size: 1.25rem;
  position: relative;
  margin: -20rem 0 -27rem 0;
  padding: 25rem 0 50rem 0;
  z-index: -1;
  overflow-x: hidden;
  overflow-y: hidden;

  @media only screen and (max-width: 991px) {
    font-size: 1rem;
    padding: 20rem 0 45rem 0;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 15rem 0 40rem 0;
  }

  .img-culture {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: -400px;
    z-index: 10;

    @media only screen and (max-width: 991px) {
      width: 1100px;
      height: 1112px;
      top: -200px;
    }
  }

  .description {
    width: 45%;
    font-weight: bold;

    @media only screen and (max-width: 1124px) {
      width: 55%;
      // font-size: 1.25rem;
    }

    @media only screen and (max-width: 991px) {
      width: 75%;
      font-size: 1.25rem;
    }

    @media only screen and (max-width: 768px) {
      width: 80%;
      font-size: 1.25rem;
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    position: relative;

    @media only screen and (max-width: 991px) {
      margin-top: 6.5rem;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 7.9rem;
    }

    &__circle-info {
      display: grid;
      min-width: 380px;
      min-height: 380px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        '. care .'
        'persistence title integrity'
        '. clarity .';
      background-color: #d993ff00;
      border-radius: 200rem;
      position: relative;
      @media only screen and (max-width: 1124px) {
        margin-left: -1rem;
      }
      @media only screen and (max-width: 991px) {
        margin-left: -3.75rem;
        margin-top: -1.5rem;
        transform: scale(0.75);
        z-index: 1000;
      }
      @media only screen and (max-width: 768px) {
        margin-left: 0rem;
        margin-top: 0rem;
        transform: scale(1);
        grid-template-areas:
          '. persistence .'
          'clarity title care'
          '. integrity .';
      }

      &--title {
        grid-area: title;
        background-color: #d993ff;
        border: 2px solid $purple;
        font-size: 23px;
        border-radius: 500px;
        box-sizing: border-box;
        z-index: 1000;
        transform: scale(1.1);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      &--care,
      &--persistence,
      &--clarity,
      &--integrity {
        place-self: center;
        font-weight: bold;
        font-size: 15px;
        display: flex;
        gap: 18px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1000;
      }
      &--care {
        grid-area: care;
      }
      &--persistence {
        grid-area: persistence;
      }
      &--clarity {
        grid-area: clarity;
      }
      &--integrity {
        grid-area: integrity;
        background-color: $purple;
        color: white;
      }
    }

    &__values {
      color: $white;
      flex-basis: 40%;
      z-index: 1000;
      position: relative;

      @media only screen and (max-width: 991px) {
        flex-basis: 40%;
      }

      &--title-pink {
        font-weight: bold;
        color: $pink3;
      }

      &--title {
        font-weight: bold;
        margin-top: 20px;
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          margin-top: 0.3rem;
          background: no-repeat url('../assets/svg/check-white.svg');
          height: 20px;
          width: 20px;
          left: -1.875rem;
        }
      }
    }
  }
}

.the-company_our-culture_mobile {
  display: block;
  font-size: 1.25rem;
  position: relative;
  margin-bottom: -250px;
  padding: 0;
  z-index: -1;
  overflow-x: hidden;
  overflow-y: hidden;

  @media only screen and (max-width: 375px) {
    margin-bottom: -150px;
  }

  .img-culture {
    position: absolute;
    left: 0;
    top: 150px;

    @media only screen and (max-width: 375px) {
      top: 160px;
    }
  }

  .container {
    padding-top: 0;
    padding-bottom: 0;

    &__description {
      font-weight: bold;
    }
  }

  .circle-info {
    display: grid;
    width: 85%;
    height: 35%;
    grid-template-columns: 1fr 1fr 1fr;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000000;
    margin: 9.5rem auto 0 auto;
    inset: 0;

    transform: scale(1);
    grid-template-areas:
      '. persistence .'
      'clarity title care'
      '. integrity .';
    @media only screen and (max-width: 730px) {
      margin-top: 9rem;
    }
    @media only screen and (max-width: 630px) {
      height: 32%;
    }
    @media only screen and (max-width: 580px) {
      margin: 8.8rem auto 0 auto;
    }

    &--title {
      grid-area: title;
      font-size: 40px;
      line-height: 50px;
      max-height: 280px;
      text-align: center;
      border-radius: 500px;
      box-sizing: border-box;
      z-index: 1000;

      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 700px) {
        font-size: 30px;
        margin-top: -2rem;
        line-height: 30px;
      }
      @media only screen and (max-width: 630px) {
        max-height: 300px;
        line-height: 50px;
      }
      @media only screen and (max-width: 580px) {
        max-height: 130px;
        margin-top: -5rem;
        line-height: 30px;
      }
      @media only screen and (max-width: 530px) {
        max-height: 120px;
        margin-top: -5rem;
      }
      @media only screen and (max-width: 495px) {
        max-height: 110px;
        margin-top: -5rem;
      }
      @media only screen and (max-width: 485px) {
        max-height: 100px;
        font-size: 24px;
      }
      @media only screen and (max-width: 411px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 395px) {
        max-height: 80px;
      }
      @media only screen and (max-width: 375px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 346px) {
        line-height: 20px;
        max-height: 67px;
      }
      @media only screen and (max-width: 342px) {
        max-height: 87px;
        line-height: 20px;
      }

      @media only screen and (max-width: 330px) {
        font-size: 16px;
      }
    }
    &--title,
    &--care,
    &--persistence,
    &--clarity,
    &--integrity {
      @media only screen and (max-width: 700px) {
        transform: scale(1.4);
        margin-top: -2rem;
      }
      @media only screen and (max-width: 650px) {
        transform: scale(1.3);
        margin-top: -4rem;
      }
      @media only screen and (max-width: 630px) {
        transform: scale(1.2);
      }
      @media only screen and (max-width: 580px) {
        margin-top: -2rem;
      }
    }
    &--care,
    &--persistence,
    &--clarity,
    &--integrity {
      place-self: center;
      font-weight: bold;
      font-size: 15px;
      display: flex;
      gap: 18px;
      transform: scale(2);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1000;
      @media only screen and (max-width: 700px) {
        margin-top: -1rem;
      }
      @media only screen and (max-width: 650px) {
        transform: scale(1.5);
      }
      @media only screen and (max-width: 630px) {
        transform: scale(1.5);
      }
      @media only screen and (max-width: 580px) {
        transform: scale(1.3);
        margin-top: -2rem;
      }
      @media only screen and (max-width: 490px) {
        transform: scale(1.2);
      }
      @media only screen and (max-width: 395px) {
        transform: scale(1.1);
      }
      @media only screen and (max-width: 370px) {
        transform: scale(1);
      }
      @media only screen and (max-width: 345px) {
        transform: scale(0.9);
      }
    }
    &--care,
    &--clarity,
    &--integrity {
      @media only screen and (max-width: 580px) {
        margin-top: -10rem;
      }
      @media only screen and (max-width: 450px) {
        margin-top: -9rem;
      }
      @media only screen and (max-width: 375px) {
        margin-top: -6.5rem;
      }
      @media only screen and (max-width: 355px) {
        margin-top: -7.5rem;
      }
      @media only screen and (max-width: 342px) {
        margin-top: -5rem;
      }
    }
    &--care {
      grid-area: care;
    }
    &--persistence {
      grid-area: persistence;

      @media only screen and (max-width: 450px) {
        margin-top: -2.7rem;
      }
      @media only screen and (max-width: 395px) {
        margin-top: -3rem;
      }
      @media only screen and (max-width: 375px) {
        margin-top: -1.6rem;
      }
    }
    &--clarity {
      grid-area: clarity;
    }
    &--integrity {
      grid-area: integrity;
      background-color: $purple;
      color: white;
    }
  }

  .wrapper {
    padding: 120% 0;
    text-align: center;
    @media only screen and (max-width: 375px) {
      padding: 90% 0;
    }

    &__values {
      color: $white;
      position: relative;
      @media only screen and (max-width: 340px) {
        font-size: 1rem;
      }

      &--title-pink {
        margin-top: 4rem;
        font-weight: bold;
        color: $pink3;

        @media only screen and (max-width: 475px) {
          margin-top: 8rem;
        }
        @media only screen and (max-width: 421px) {
          margin-top: 5rem;
        }
        @media only screen and (max-width: 375px) {
          margin-top: 8rem;
        }
      }

      &--title {
        font-weight: bold;
        margin-top: 2.0625rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 375px) {
          margin-top: 1.2625rem;
        }

        .icon {
          margin-right: 0.625rem;
        }
      }
    }
  }
}
