.home_contact {
  color: $purple;
  display: block;
  margin: 0 0 8rem 0;
  position: relative;
  @media only screen and (max-width: 475px) {
    max-height: 900px;
    margin: 0 0 22rem 0;
  }
  @media only screen and (max-width: 440px) {
    margin: 0 0 15rem 0;
  }
  @media only screen and (max-width: 411px) {
    margin: 0 0 8rem 0;
  }
  @media only screen and (max-width: 360px) {
    margin: 0 0 -6rem 0;
  }

  a {
    text-decoration: none;
  }

  .container {
    margin: 0 auto;
    background: no-repeat right url(../assets/svg/contact-nomi.svg);
    background-size: contain;
    border-radius: 30px 30px 80px 30px;

    @media only screen and (max-width: 991px) {
      background: #f1f7fa;
    }
    @media only screen and (max-width: 475px) {
      background: rgba(255, 255, 255, 0);
      border-radius: 0;
    }
  }

  &__info {
    z-index: 5;
    padding: 77px 0px 90px 61px;
    font-size: 1.875rem;
    width: 75%;
    position: relative;

    @media only screen and (max-width: 991px) {
      padding: 77px 61px;
      width: 100%;
      text-align: center;
    }
    @media only screen and (max-width: 475px) {
      padding: 0;
      margin-top: 0;
      text-align: left;
    }

    .title {
      font-weight: bold;
      @media only screen and (max-width: 411px) {
        padding-top: 50px;
      }
      @media only screen and (max-width: 345px) {
        padding-top: 20px;
      }
    }
    .description {
      font-weight: 400;
      line-height: 2.875rem;
      margin-top: 50px;
      margin-bottom: 50px;
      max-width: 75%;
      @media only screen and (max-width: 1124px) {
        max-width: 60%;
      }
      @media only screen and (max-width: 991px) {
        max-width: 100%;
      }
      @media only screen and (max-width: 475px) {
        margin-top: 20px;
        margin-bottom: 450px;
        max-width: 60%;
      }
      @media only screen and (max-width: 460px) {
        margin-bottom: 430px;
      }
      @media only screen and (max-width: 440px) {
        margin-bottom: 410px;
      }
      @media only screen and (max-width: 425px) {
        margin-bottom: 390px;
        max-width: 70%;
      }
      @media only screen and (max-width: 420px) {
        margin-bottom: 390px;
      }
      @media only screen and (max-width: 411px) {
        margin-bottom: 330px;
      }
      @media only screen and (max-width: 392px) {
        margin-bottom: 300px;
      }
      @media only screen and (max-width: 377px) {
        margin-bottom: 330px;
        font-size: 1.65rem;
        line-height: 2.375rem;
        max-width: 65%;
      }
      @media only screen and (max-width: 367px) {
        margin-bottom: 310px;
      }
      @media only screen and (max-width: 361px) {
        margin-bottom: 290px;
        font-size: 1.45rem;
      }
      @media only screen and (max-width: 346px) {
        margin-bottom: 275px;
      }
      @media only screen and (max-width: 341px) {
        margin-bottom: 280px;
      }
      @media only screen and (max-width: 330px) {
        margin-bottom: 260px;
        max-width: 70%;
      }
      @media only screen and (max-width: 322px) {
        margin-bottom: 245px;
      }
    }
    .btn-desktop {
      display: inline-block;
      padding: 0;
      @media only screen and (max-width: 475px) {
        display: none;
      }
    }
    .btn-mobile {
      display: none;
      @media only screen and (max-width: 475px) {
        display: block;
        padding: 0.375rem 0;
      }
      @media only screen and (max-width: 359px) {
        font-size: 1rem;
      }
    }

    .btn-comp {
      width: 100%;
      @media only screen and (max-width: 359px) {
        font-size: 1rem;
        span img {
          margin-left: 0;
          max-width: 15px !important;
        }
      }
    }
  }

  &__bg {
    background-image: linear-gradient(
      to left,
      rgba(255, 80, 80, 0) 0%,
      rgba(255, 255, 255, 1) 15%,
      #f1f7fa 50%,
      #f1f7fa 65%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: -1;
    position: absolute;
    inset: 0;
    margin: auto;
    left: -970px;
    max-height: 600px;
    border-radius: 30px 30px 30px 80px;

    @media only screen and (max-width: 1024px) {
      max-height: 700px;
    }
    @media only screen and (max-width: 991px) {
      background: white;
    }
    @media only screen and (max-width: 475px) {
      display: none;
    }
  }

  &__bg-mobile-img {
    display: none;
    @media only screen and (max-width: 475px) {
      display: block;
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      width: 100%;
    }
  }
}
