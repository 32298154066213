.payroll_interaction {
  color: $purple;
  @media only screen and (max-width: 475px) {
    padding-bottom: 100px !important;
  }

  .container {
    padding-top: 8.125rem;
    @media only screen and (max-width: 475px) {
      padding-top: 50px;
    }
  }

  .margin {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .fw-bold {
    font-weight: bold;
    color: #521798;
  }

  .description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    @media only screen and (max-width: 475px) {
      margin-top: -50px;
    }

    &__img {
      margin-right: 40px;
      @media only screen and (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
    p {
      margin-bottom: 0;
    }

    @media only screen and (max-width: 768px) {
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .slider {
    position: relative;
    width: 1080px;
    height: 662px;
    @media only screen and (max-width: 1124px) {
      height: 580px;
    }
    @media only screen and (max-width: 991px) {
      height: 410px;
    }
    @media only screen and (max-width: 768px) {
      height: 320px;
    }
    @media only screen and (max-width: 475px) {
      height: 270px;
    }
    @media only screen and (max-width: 411px) {
      height: 230px;
    }
    @media only screen and (max-width: 340px) {
      height: 200px;
    }

    .slide1,
    .slide2,
    .slide3,
    .slide4,
    .slide5 {
      position: absolute;
      inset: 0;
      margin: 0 auto;
      width: 100%;
      height: 100%;
    }

    .slide1 {
      background-image: url(../assets/img/interaction.jpg);
      background-repeat: no-repeat;
      background-size: contain;
      animation: fade1 15s infinite;
    }
    .slide2 {
      background-image: url(../assets/img/vacations-permissions.jpg);
      background-repeat: no-repeat;
      background-size: contain;
      animation: fade2 15s infinite;
    }
    .slide3 {
      background-image: url(../assets/img/disabilities.jpg);
      background-repeat: no-repeat;
      background-size: contain;
      animation: fade3 15s infinite;
    }
    .slide4 {
      background-image: url(../assets/img/settlements.jpg);
      background-repeat: no-repeat;
      background-size: contain;
      animation: fade4 15s infinite;
    }
    .slide5 {
      background-image: url(../assets/img/dining-room.jpg);
      background-repeat: no-repeat;
      background-size: contain;
      animation: fade5 15s infinite;
    }
    @keyframes fade1 {
      0% {
        opacity: 1;
      }
      17% {
        opacity: 1;
      }
      20% {
        opacity: 0;
      }
      97% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes fade2 {
      0% {
        opacity: 0;
      }
      17% {
        opacity: 0;
      }
      20% {
        opacity: 1;
      }
      37% {
        opacity: 1;
      }
      40% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes fade3 {
      0% {
        opacity: 0;
      }
      37% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      57% {
        opacity: 1;
      }
      60% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes fade4 {
      0% {
        opacity: 0;
      }
      57% {
        opacity: 0;
      }
      60% {
        opacity: 1;
      }
      77% {
        opacity: 1;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes fade5 {
      0% {
        opacity: 0;
      }
      77% {
        opacity: 0;
      }
      80% {
        opacity: 1;
      }
      97% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  .cards {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
    justify-items: center;
    text-align: center;

    @media only screen and (max-width: 991px) {
      grid-gap: 1.2rem;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
    }

    @media only screen and (max-width: 475px) {
      display: block;
    }

    .card {
      background: transparent;
      border: none;
      @media only screen and (max-width: 475px) {
        margin-top: 50px;
      }

      &__title {
        font-weight: bold;
        font-size: 1.25rem;
        margin: 1.25rem 0 2.75rem 0;
        &::after {
          content: '';
          display: block;
          position: absolute;
          margin-top: 1.25rem;
          background: linear-gradient(to right, #aa20ff 0%, #ff14c1 100%);
          height: 4px;
          width: 65px;
          right: calc(50% - 32px);
          border-radius: 5rem;
        }
      }

      &__description {
        font-size: 1rem;
        line-height: 26px;
        @media only screen and (max-width: 475px) {
          max-width: 92%;
          margin: 0 auto;
        }
      }

      &__box-purple {
        background: $purple;
        color: white;
        text-align: left;
        font-size: 0.875rem;
        padding: 0.875rem 0.8125rem 1.125rem 0.875rem;
        border-radius: 0.625rem;
        margin-top: 0.625rem;
        @media only screen and (max-width: 475px) {
          padding: 14px 14px 18px 14px;
          margin: 10px 12px 0 12px;
        }

        p {
          margin-bottom: 0;
        }

        &--left-align {
          display: flex;
          justify-content: flex-start;
        }

        &--margin-top {
          margin-top: 0.5rem;
        }

        .dicoma-link {
          font-weight: bold;
          color: #14dbff;
        }
      }
    }
  }
  .asterisk {
    font-size: 0.75rem;
  }
}
