.payroll_calculation {
  color: $pink2;
  margin-top: 0;

  .container {
    padding-top: 8.125rem;
    @media only screen and (max-width: 475px) {
      padding-top: 50px;
    }
  }

  ul,
  ol,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    // border: 0;
  }

  li {
    margin-left: 0.875rem;
  }

  .margin {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .results-validation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.625rem;

    @media only screen and (max-width: 768px) {
      display: block;
    }

    &__text {
      flex-basis: 45%;
      font-size: 1rem;

      &--title {
        font-weight: bold;
        display: flex;

        span {
          margin-left: 0.625rem;
        }
      }

      &--description {
      }

      &--asterisk {
        font-style: italic;
      }
    }

    &__img {
      flex-basis: 50%;
    }
  }

  .basic-reports {
    font-weight: bold;
    font-size: 1.25rem;

    .cards {
      display: grid;
      grid-gap: 2.375rem 5.625rem;
      align-items: start;
      justify-items: center;

      grid-template-columns: repeat(3, 1fr);

      grid-template-areas:
        'uno dos tres'
        'cuatro cinco .'
        'transferencia timbrado .';

      text-align: center;

      @media only screen and (max-width: 991px) {
        grid-gap: 2.375rem;
        grid-template-columns: repeat(2, 1fr);

        grid-template-areas:
          'uno dos'
          'tres cuatro'
          'cinco .'
          'transferencia timbrado';
      }

      @media only screen and (max-width: 475px) {
        display: block;
      }

      .uno {
        grid-area: uno;
      }

      .dos {
        grid-area: dos;
      }

      .tres {
        grid-area: tres;
      }

      .cuatro {
        grid-area: cuatro;
      }

      .cinco {
        grid-area: cinco;
      }

      .transferencia {
        grid-area: transferencia;
        background: $gray5 !important;
        width: 100%;

        &::after {
          content: '';
          display: block;
          position: absolute;
          height: 12px;
          width: 100%;
          bottom: -12px;
          right: 0;
          background: repeat url('../assets/icons/peaks-gray.svg') !important;
        }

        .card__description {
          text-align: left !important;
        }
      }

      .timbrado {
        grid-area: timbrado;
        background: $gray5 !important;
        width: 100%;

        &::after {
          content: '';
          display: block;
          position: absolute;
          height: 12px;
          width: 100%;
          bottom: -12px;
          right: 0;
          background: repeat url('../assets/icons/peaks-gray.svg') !important;
        }

        .card__description {
          text-align: left !important;
        }
      }

      .card {
        border: none;
        position: relative;
        font-size: 1rem;
        line-height: 1.625rem;
        height: 100%;
        border-radius: 1.25rem 1.25rem 0 0;
        padding: 1.875rem 1.25rem 2.5rem 1.25rem;
        background: linear-gradient(#f9e3ff 0%, rgba(249, 227, 255, 0.35) 100%);

        &::after {
          content: '';
          display: block;
          position: absolute;
          height: 12px;
          width: 100%;
          bottom: -12px;
          right: 0;
          background: repeat url('../assets/icons/peaks.svg');
        }

        @media only screen and (max-width: 475px) {
          margin-top: 1.875rem;
        }

        &__title {
          font-size: 1.25rem;
          padding-bottom: 2.5rem;
          position: relative;

          &::after {
            content: '';
            display: block;
            position: absolute;
            margin-top: 1.25rem;
            background: transparent;
            height: 2px;
            width: 80%;
            right: 10%;
            border-top: 2px dashed #aa20ff;
          }
        }

        &__description {
          margin-bottom: 7.825rem;
        }

        &__icons {
          position: absolute;
          bottom: 0;
          right: 0;
          margin-top: 30px;
          padding: 0 1.25rem 1.75rem 1.25rem;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          &--icon {
            margin-left: 1.25rem;
          }
        }
      }
    }
  }
}
