.payroll_title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.875rem;
  margin-bottom: 40px;
  padding-top: 1.25rem;
  @media only screen and (max-width: 475px) {
    padding-top: 0;
  }

  .icons {
    margin-right: 20px;
    @media only screen and (max-width: 475px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .icon-number {
      margin-right: 20px;
    }
  }
  @media only screen and (max-width: 475px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 30px;
  }
}

.payroll_description {
  line-height: 2.25rem;
  p {
    margin: 0;
  }
  font-size: 1.25rem;
  li {
    margin: 0;
  }
}
