.software_title {
  padding-top: 1.25rem;
  h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 2.25rem;
    margin-inline-start: 20px;
    margin-block-end: 0;
  }
  p {
    font-size: 1.25rem;
  }
  .layout-icon {
    width: 70px;
    text-align: center;
  }
  @media only screen and (max-width: 475px) {
    .title-phone {
      flex-direction: column;
      gap: 1.25rem;
    }
    h2 {
      margin-inline-start: 0;
    }
  }
}
