.page-error {
  height: 100vh;
}
.purple-line {
  height: 4px;
  width: 100%;
  background: linear-gradient(to right, #8307fe 0%, #ea07ad 100%);
}

.error_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 46.75rem;
  margin-top: 11%;
  margin-bottom: 13%;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 475px) {
    margin-top: 20%;
  }
  .illust {
    margin-bottom: 2rem;
  }

  .title {
    font-size: 1rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 0.9375rem;
    align-self: flex-start;
    margin-left: 0.75rem;
    .icon {
      margin-right: 0.625rem;
    }
  }

  .description {
    background-color: #fff500;
    color: #000;
    font-size: 2.5rem;
    margin-bottom: 12%;
    border-radius: 1.25rem;
    padding: 0.75em 1.5em;
    text-align: center;
    span {
      font-weight: bold;
    }
  }
}
