.dicoma_hero {
  height: 900px;
  position: relative;
  @media only screen and (max-width: 768px) {
    height: 800px;
  }
  @media only screen and (max-width: 476px) {
    height: 600px;
  }
  &__bg {
    position: absolute;
    z-index: 2;
    max-height: 565px;
    max-width: 1200px;
    background: center url(../assets/img/hero-d.png);
    background-size: contain;
    background-repeat: no-repeat;
    inset: 0;
    margin: 21% auto 0 auto;
    overflow: hidden;
    pointer-events: none;

    @media only screen and (max-width: 1400px) {
      margin: 25% auto 0 auto;
    }
    @media only screen and (max-width: 1200px) {
      margin: 30% auto 0 auto;
    }
    @media only screen and (max-width: 1100px) {
      margin: 35% auto 0 auto;
    }
    @media only screen and (max-width: 820px) {
      margin: 40% auto 0 auto;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .hero-text {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    @media only screen and (max-width: 991px) {
      margin-top: 45px;
    }
    @media only screen and (max-width: 476px) {
      margin-top: 45px;
    }
    h1 {
      font-size: 2.875rem;
      font-weight: 400;
      line-height: 3.75rem;
      color: $white;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 511px) {
        font-size: 2.4rem;
        line-height: 3.25rem;
      }
      @media only screen and (max-width: 476px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
        width: 100%;
      }
    }
  }
  .sub-title {
    font-size: 3.125rem;
    font-weight: bold !important;
    margin-bottom: 581px;
    text-align: center !important;
  }
}
