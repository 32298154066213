// Colores
// Primario
$purple: #4b2192;

//Secundario
$purple1: #521798;
$purple2: #7b4bce;
$purple3: #4b2790;
$green: #90fe9a;
$pink: #ff14c1;
$pink2: #aa20ff;
$pink3: #d993ff;
$blue: #253858;
$blue1: #14dbff;
$blue2: #3990c6;
$yellow: HSL(60, 100%, 50%);
$yellowHover: linear-gradient(
  to right,
  HSL(60, 100%, 60%) 0%,
  HSL(280, 100%, 80%) 90%
);

//Neutral
$gray1: #f7f8f9;
$gray2: #f1f7fa;
$gray3: #73848d;
$gray4: #f6f8f8;
$gray5: #f5f5f5;
$gray6: #f1f0fa;
$gray7: #707070;
$gray8: #eef1f9;
$white: #fff;
$black: #000;
