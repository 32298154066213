.contact {
  margin-top: 0;

  .container {
    @media (max-width: 475px) {
      padding-top: 20px;
      padding-bottom: 50px;
    }
  }

  .purple-line-mobile {
    display: none;
    @media (max-width: 475px) {
      width: 100%;
      height: 2px;
      background: $purple;
      // margin-top: 150px;
      margin-bottom: 50px;
      display: block;
    }
  }
  p {
    font-size: 1.625rem;
  }
  h1 {
    font-weight: bold;
    font-size: 3.125rem;
  }

  @media (max-width: 475px) {
    h1 {
      font-size: 1.875rem;
      margin-top: 50px;
    }

    div:last-child {
      margin-top: 40px;
    }
  }
}
