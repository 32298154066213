.dicoma_manager {
  margin-top: 0;

  .card {
    border: none;
    text-align: center;
    h2 {
      margin-bottom: 50px;
    }
    h4 {
      margin-top: 156px;
      margin-bottom: 13px;
      font-size: 1.25rem;
      @media only screen and (max-width: 475px) {
        margin-top: 0px;
      }
    }
    .sub-title-middle {
      margin-top: 61px;
      margin-bottom: 27px;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 32%;
        bottom: 2.7%;
        left: 0;
        background: no-repeat url('../assets/img/arrows-manager.svg') bottom;
        background-size: contain;
        @media only screen and (max-width: 1124px) {
          bottom: 2%;
          height: 34%;
          background: no-repeat url('../assets/img/arrows-manager-02.svg') bottom;
        }
        @media only screen and (max-width: 991px) {
          display: none;
        }
      }
    }
    p {
      margin-top: 0;
      font-size: 1.25rem;
      line-height: 2.25rem;
    }
  }

  .button-dicoma {
    border-radius: 40px;
    background-color: $blue1;
    padding: 12px 44px;
    border: none;
    font-size: 1.25rem;
    color: $purple1;
    margin-top: 9px;
    position: relative;
  }

  .row {
    div:first-child {
      margin-top: 46px;
    }
    div:last-child {
      margin-top: 46px;
    }
  }
  @media only screen and (max-width: 768px) {
    .card {
      h2 {
        margin-bottom: 45px;
      }
      h4 {
        margin-top: 0;
        margin-bottom: 13px;
        font-size: 1.25rem;
        text-align: left;
        padding: 0 12px;
      }
      .sub-title-middle {
        margin-top: 61px;
        margin-bottom: 27px;
        text-align: left;
        padding: 0 12px;
      }
      p {
        margin-top: 0;
        font-size: 1.25rem;
        text-align: left;
        line-height: 2.25rem;
        padding: 0 12px;
      }
      div:last-child {
        margin-top: 20px !important;
      }
    }

    .row {
      div:first-child {
        margin-top: 0px;
      }
      div:last-child {
        margin-top: 80px;
      }
    }
  }
}
