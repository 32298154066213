.payroll_hero {
  color: $white;
  line-height: 3.325rem;
  max-height: 760px;
  margin-bottom: 350px;
  position: relative;
  @media only screen and (max-width: 476px) {
    max-height: 600px;
    margin-bottom: 0;
  }

  .hero-text {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    @media only screen and (max-width: 991px) {
      margin-top: 45px;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 50px;
    }
    @media only screen and (max-width: 476px) {
      margin-top: 30px;
    }
  }
  h1 {
    font-size: 2.875rem;
    text-align: center;
    margin: 14px;
    margin-bottom: 9px;
    font-weight: 300;
  }
  .sub-title {
    font-size: 3.125rem;
    padding-bottom: 64px;
    font-weight: bold;
  }

  .grid-center {
    display: grid;
    place-items: center;
    z-index: 1000;
    position: relative;
  }

  .loop-img {
    padding: 12px;
    z-index: 1000;
  }
  .loop-text {
    z-index: 1000;
    position: absolute;
    inset: 0;
    margin: auto;
  }

  .referenceAnimationBox {
    width: 100%;
    height: 20%;
    // background-color: thistle;
    position: absolute;
    bottom: -500px;
    margin-bottom: 0;
    z-index: -5;
    @media only screen and (min-width: 2560px) {
      bottom: -1050px;
    }
    @media only screen and (max-width: 768px) {
      bottom: -540px;
    }
    @media only screen and (max-width: 476px) {
      margin-bottom: 110px;
    }
    @media only screen and (max-width: 475px) {
      display: none;
    }
  }

  .hide-phone {
    display: center;
    @media only screen and (max-width: 475px) {
      display: none;
    }
  }

  @media only screen and (max-width: 475px) {
    h1 {
      font-size: 1.5rem;
    }
    .sub-title {
      font-size: 1.5rem;
      width: 277px;
    }

    height: 600px;
  }
}
