.software_hero {
  color: $white;
  line-height: 3.325rem;
  height: 1600px;
  position: relative;
  margin-bottom: -480px;
  @media only screen and (max-width: 1100px) {
    margin-bottom: -420px;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: -500px;
  }
  @media only screen and (max-width: 816px) {
    margin-bottom: -450px;
  }
  @media only screen and (max-width: 800px) {
    margin-bottom: -390px;
  }
  @media only screen and (max-width: 476px) {
    height: 600px;
    margin-bottom: 220px;
  }

  .hero-text {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    @media only screen and (max-width: 991px) {
      margin-top: 45px;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 50px;
    }
    @media only screen and (max-width: 476px) {
      margin-top: 30px;
    }
    h1 {
      text-align: center;
      margin: 14px;
      margin-bottom: 9px;
      font-weight: 300;
      font-size: 2.875rem;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 768px) {
        width: 85%;
      }
      @media only screen and (max-width: 568px) {
        width: 95%;
      }
      @media only screen and (max-width: 476px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
        width: 65%;
      }
      @media only screen and (max-width: 411px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
        width: 85%;
      }
    }
  }
  .sub-title {
    font-size: 3.125rem;
    padding-bottom: 64px;
    font-weight: bold !important;
  }
  .img-cycle {
    z-index: 10;
    @media only screen and (max-width: 620px) {
      padding: 12px;
    }
    @media only screen and (max-width: 476px) {
      margin-top: -40px;
    }
  }
  .referenceAnimationBox {
    width: 100%;
    height: 40%;
    position: absolute;
    bottom: 0;
    margin-bottom: 0px;
    z-index: 5;
    @media only screen and (min-width: 2560px) {
      margin-bottom: -280px;
    }
    @media only screen and (max-width: 476px) {
      margin-bottom: -480px;
    }
  }
  @media only screen and (max-width: 475px) {
    h1 {
      font-size: 1.5rem;
    }
    .sub-title {
      font-size: 1.5rem;
      width: 277px;
    }

    height: 600px;
    .img-cycle {
      width: 260px;
      height: 256px;
    }
  }
}
