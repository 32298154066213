.the-company_our-goal {
  position: relative;
  margin-top: 250px;

  .container {
    position: relative;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 82px 192px 123px 168px;
    align-content: stretch;
    gap: 10px;
  }
  .hide-on-desktop {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
  .hide-on-mobile {
    display: block;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .how {
    // background-color: aquamarine;
    font-size: 22px;
    text-align: center;
    position: relative;
    align-self: end;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    &::before {
      content: url('../assets/svg/how-arrow-desktop.svg');
      position: absolute;
      margin: -2rem auto 0 auto;
      inset: 0;
      pointer-events: none;
    }
  }
  .vision {
    grid-column: 1 / 2;
    grid-row: 2 / 5;
  }
  .goal {
    // background: rgba(0, 0, 0, 0.144);
    font-size: 30px;
    text-align: center;
    line-height: 36px;
    color: white;
    position: relative;
    margin-top: 2rem;

    align-self: center;
    grid-column: 2 / 3;
    grid-row: 2 / 3;

    &::before {
      content: '';
      position: absolute;
      border-radius: 5rem;
      width: 138px;
      height: 138px;
      margin: -2.125rem auto 0 auto;
      inset: 0;
      z-index: -1;
      background: linear-gradient(120deg, #aa20ff 0%, #ff14c1 100%);
    }
  }
  .where {
    // background-color: crimson;
    font-size: 22px;
    text-align: center;
    position: relative;
    // margin-top: 2rem;
    margin: 0 auto 0 4rem;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    &::after {
      content: url('../assets/svg/where-arrow-desktop.svg');
      position: absolute;
      margin: -0.75rem auto 0 -4rem;
      inset: 0;
      pointer-events: none;
    }
  }
  .mission {
    grid-column: 3 / 4;
    grid-row: 2 / 5;
  }

  &__icon {
    &--circle {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: -22%;
      display: flex;
      justify-content: center;
      z-index: 20000;

      @media only screen and (max-width: 1200px) {
        width: 45%;
        top: -7%;
      }
    }
  }

  .card {
    border: none;
    border-radius: 1em;
    // background: pink;
    color: $purple1;
    max-width: 18.75rem;

    &__body {
      @media only screen and (max-width: 475px) {
        &:first-child {
          margin-bottom: 30px;
        }
      }
      &__illust {
        max-width: 280px;
        max-height: 238px;
        // margin: 0 auto;
      }

      h3 {
        font-weight: bold;
        font-size: 1.875rem;
        text-align: center;
        padding-top: 1rem;
      }
    }
    &__arrow {
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 1124px) {
    .wrapper {
      grid-template-columns: 1fr 2fr 1fr;
      grid-template-rows: 82px 192px 123px;
      align-content: stretch;
      gap: 10px;
    }
    .how {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      &::before {
        margin: -2rem auto 0 -1.3rem;
        inset: 0;
      }
    }
    .vision {
      grid-column: 1 / 2;
      grid-row: 2 / 4;
    }
    .goal {
      margin-top: 2rem;
      align-self: center;
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      &::before {
        margin: -2.125rem auto 0 auto;
        inset: 0;
      }
    }
    .where {
      // background: rgba(0, 0, 0, 0.63);
      margin: 0 auto 0 2rem;
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      &::after {
        content: url('../assets/svg/where-arrow-tablet.svg');
        margin: -0.75rem auto 0 0.5rem;
      }
    }
    .mission {
      grid-column: 3 / 4;
      grid-row: 2 / 4;
    }
  }
  @media only screen and (max-width: 991px) {
    .how {
      margin-bottom: -1rem;
      z-index: 2;
      &::before {
        content: url('../assets/svg/how-arrow-tablet.svg');
        margin: -3.5rem auto 0 -2.5rem;
        inset: 0;
      }
    }
    .where {
      margin: 0 auto 0 1rem;
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      &::after {
        content: url('../assets/svg/where-arrow-tablet.svg');
        margin: -0.75rem auto 0 -1.7rem;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .wrapper {
      display: grid;
      grid-template-areas:
        'goal'
        'where'
        'vision'
        'how'
        'mission';
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      place-content: center;
      gap: 10px;
      // background-color: rgba(202, 202, 202, 0.376);
    }
    .how {
      margin: unset;
      grid-area: how;
      &::before {
        content: '';
      }
      .alignment-text-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .text-mobile {
          position: absolute;
          margin: 0 auto 0 10rem;
        }
      }
    }
    .vision {
      grid-area: vision;
      justify-self: center;
    }
    .goal {
      // background: rgba(0, 0, 0, 0.63);
      margin-top: 2rem;
      align-self: center;
      grid-area: goal;
      margin-bottom: 2.5rem;
      &::before {
        margin: -2.125rem auto 0 auto;
        inset: 0;
      }
    }
    .where {
      margin: unset;
      grid-area: where;
      // background-color: crimson;
      &::after {
        content: '';
      }
      .alignment-text-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .text-mobile {
          position: absolute;
          margin: 0 10rem 0 auto;
        }
      }
    }
    .mission {
      grid-area: mission;
      justify-self: center;
    }
  }
}
