.return_home {
  font-size: 18px;
  font-weight: bold;
  a {
    text-decoration: none;
    color: inherit;
  }
  img {
    margin-right: 10px;
  }
}
