.my_company_welcome {
  font-size: 1.25rem;
  position: relative;

  .text-center {
    text-align: center;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .title {
    font-size: 1.875rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 0.625rem;
    }

    &__company {
      font-size: 3.75rem;
      display: flex;

      @media only screen and (max-width: 768px) {
        font-size: 2.5rem;
        margin-bottom: 8.4375rem;
      }

      &--name {
        font-weight: bold;
        position: relative;

        .name-description {
          font-weight: 400;
          font-size: 22px;
          display: flex;
          position: absolute;
          top: -6.5rem;
          right: -4.5rem;
          img {
            margin-top: 1.75rem;
            margin-right: 0.5rem;
          }
          .img-mobile {
            margin-top: -2.3rem;
          }
          span {
            text-align: center;
          }

          @media only screen and (max-width: 768px) {
            bottom: -6rem;
            top: unset;
            left: -6rem;
          }
          @media only screen and (max-width: 475px) {
            right: unset;
          }
          @media only screen and (max-width: 420px) {
            margin-left: 3rem;
          }
        }
      }

      &--registered {
        font-size: 1.875rem;
        align-self: flex-start;
      }
    }
  }

  .description {
    font-weight: bold;
    line-height: 2.25rem;
    margin-top: 3.125rem;
    margin-bottom: 3.4375rem;
  }

  .moduls {
    display: flex;
    justify-content: flex-start;
    gap: 0.25rem;
    margin-top: 2.5rem;
    position: relative;
    &:active {
      background: white !important;
      color: $purple !important;
    }

    .modul-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $gray8;
      padding: 0.625rem 1.625rem;
      font-size: 1.125rem;
      font-weight: bold;
      min-width: 375px;
      cursor: pointer;
      transition: 115ms;

      &:hover,
      &:active,
      &:focus {
        background: $purple;
        color: $white;
      }

      &__icon {
        margin-right: 1.25rem;
      }

      &--empty {
        background: $gray8;
        min-width: 140px;
      }
    }
    .active {
      background: $purple;
      color: $white;
    }
    .active-blue {
      background: $blue1 !important;
      color: $white !important;
    }

    .module-blue:hover,
    &:active {
      background: $blue1;
      color: $white;
    }
  }

  .modul-tab--scroll {
    background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    min-width: 250px;
    height: 80px;
    position: absolute;
    margin-top: -5rem;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 3%;
    pointer-events: none;

    @media only screen and (max-width: 1280px) {
      margin-top: -5rem;
    }
  }

  @media only screen and (max-width: 475px) {
    .modul-tab--scroll {
      width: 100px;
      min-width: 100px;
    }
  }

  .modul-showcase {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.25rem 0 0.625rem 0;

    .img-showcase {
      max-width: 85%;
      @media only screen and (max-width: 475px) {
        max-width: 100%;
      }
    }
  }

  .modul-description {
    font-size: 1.5rem;
    text-align: center;
    padding: 7.5625rem 0 9.875rem 0;
    margin-top: -6.5rem;
    background: linear-gradient(#fff 0%, #f1f7fa 100%);
    @media only screen and (max-width: 475px) {
      padding: 7.5625rem 0 92px 0;
    }

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      gap: 1.25rem;
      &--blue {
        color: $blue1;
      }
      &--name {
        font-size: 24px;
        @media only screen and (max-width: 475px) {
          font-size: 20px;
        }
      }
      @media only screen and (max-width: 475px) {
        flex-direction: column;
      }

      &--icon {
        .icon-desktop {
          display: block;
        }
      }
    }

    &__body {
      margin-top: 1.875rem;
      line-height: 2.25rem;
      font-size: 1.25rem;
      &--blue {
        color: $blue1;
      }
    }
  }
}
