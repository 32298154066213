.approach_hero {
  color: $white;

  &__bg {
    background-image: url("../assets/img/hero-approach.jpg");
    background-size: cover;
    background-position: center;

    @media only screen and (max-width: 375px) {
      background-image: url("../assets/img/hero-approach-mobile.jpg");
      background-size: cover;
      background-position: center;
    }
  }

  h1 {
    font-size: 3.125rem;
    font-weight: bold;
    position: relative;
    padding-top: 3.8125rem;
    padding-bottom: 12.1875rem;

    @media only screen and (max-width: 375px) {
      font-size: 2.1875rem;
      padding-top: 2.25rem;
      padding-bottom: 6.6875rem;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      margin-top: 0.2rem;
      background: linear-gradient(to right, #aa20ff 0%, #ff14c1 100%);
      height: 4px;
      width: 65px;
      left: 0;
    }
  }
}
