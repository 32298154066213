.contact_sales {
  color: $purple1;
  line-height: 2.25rem;
  margin-top: 0;
  margin-bottom: 150px;

  .show {
    display: block;
  }
  .hide {
    display: none;
  }

  h3 {
    width: 100%;
    text-align: center;
    font-size: 1.375rem;
    font-weight: bold;
  }
  .description {
    width: 100%;
    text-align: center;
    font-size: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 3.25rem;
  }
  .wrapper {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top, #fff 0%, #f1f7fa 100%);
    border-radius: 2.5rem;
    padding: 4.25rem 2.5rem 3.125rem 2.5rem;
    margin-top: 7.5rem;
    @media only screen and (max-width: 411px) {
      background: none;
      padding: 4.25rem 0 3.125rem 0;
    }
    &__bg--mobile {
      display: none;
      @media only screen and (max-width: 475px) {
        display: block;
        position: absolute;
        inset: 0;
        z-index: -1;
        margin: 9.75rem 1.75rem 0 1.75rem;
        background: linear-gradient(to top, #fff 0%, #f1f7fa 100%);
        border-radius: 2.5rem;
      }
    }
    .icon {
      position: absolute;
      top: 0;
      margin-top: -3.5rem;
    }

    .form {
      position: relative;
      display: grid;
      grid-gap: 3.125rem 5rem;
      grid-template-columns: repeat(3, minmax(270px, 1fr));
      padding-bottom: 3.125rem;
      @media only screen and (max-width: 1124px) {
        grid-gap: 1.5625rem 2.5rem;
      }
      @media only screen and (max-width: 992px) {
        display: block;
        width: 100%;
      }

      &_group {
        display: flex;
        flex-flow: column wrap;
        @media only screen and (max-width: 992px) {
          margin-bottom: 3.125rem;
        }
        &--label {
          font-size: 1rem;
          font-weight: bold;
        }
      }
      &__dropdown-menu {
        position: relative;
        width: 100%;

        select {
          font-size: 0.875rem;
          font-weight: 400;
          padding: 0.6875rem 1.25rem;
          color: $purple1;
          background: #e2eaef;
          border: none;
          border-radius: 2.5rem;
          cursor: pointer;
          width: 100%;
        }
        .select--variation {
          background: #e5e3ff;
        }

        .custom-select {
          position: relative;
        }

        .custom-arrow {
          display: block;
          top: 20%;
          bottom: 20%;
          right: 1.7%;
          background: #e2eaef;
          // background: red;
          border-radius: 5rem;
          height: 60%;
          width: 2rem;
          position: absolute;
          pointer-events: none;
          @media only screen and (max-width: 992px) {
            right: 0;
          }

          &::after {
            --size: 0.45em;
            content: '';
            position: absolute;
            width: 0;
            height: 0;

            left: 0;
            top: 40%;

            border-left: var(--size) solid transparent;
            border-right: var(--size) solid transparent;
            border-top: var(--size) solid $purple1;
          }
        }
      }

      .comments {
        grid-column: 1 / 4;
        margin-bottom: 3.125rem;
        textarea {
          resize: none;
          padding: 0.625rem;
          min-height: 160px;
        }
        ::placeholder {
          color: #8fa2ac;
          font-size: 1rem;
          font-style: italic;
        }
      }
      .btn-form {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        text-align: center;
        width: 320px;

        background-image: linear-gradient(to right, $green 50%, #c9ff14 100%);
        font-weight: bold;
        color: $purple !important;
        border-radius: 10em;
        border: none;
        padding: 0.596rem 1.625rem;
        cursor: pointer;
        font-size: 1.25rem;
        background-size: 250%;
        transition: background-position 350ms;

        &:hover,
        &:active {
          background-position: right;
        }
      }
    }
  }
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #4b2790;
  background-color: #e5e3ff;
  border-color: #9c99c3;
  box-shadow: 0 0 0 0.25rem rgba(118, 108, 255, 0.5);
}
.btn-success,
.btn-success:hover {
  color: #4b2790;
  background: #e5e3ff;
  border: none;
  width: 100%;
  text-align: left;
  padding-left: 15px;
  position: relative;
}
.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  color: #4b2790;
  background: #e5e3ff;
  border: none;
}

.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  color: #4b2790;
  background-color: #e5e3ff;
  border-color: #c7c5e6;
  box-shadow: none;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #4b2192;
  text-align: left;
  list-style: none;
  background-color: #f7f7ff;
  background-clip: padding-box;
  border: 1 px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25 rem;
}
.form-check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 1.5rem;
  padding-right: 1.5em;
  margin-bottom: 0.125rem;
  gap: 0.5em;
}
.form-check-input:checked {
  background-color: #865dce;
  border: none;
}
.dropdown-toggle::after {
  display: inline-block;
  right: 1em;
  top: 0.95em;
  position: absolute;
  margin-left: 0.255em;
  content: '';
  border-top: 0.45em solid;
  border-right: 0.45em solid transparent;
  border-bottom: 0;
  border-left: 0.45em solid transparent;
}
