.approach_thinking {
  text-align: center;
  color: $purple;
  margin-top: 140px;
  margin-bottom: 100px;
  h2 {
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 2.875rem;
    position: relative;
    &::after {
      position: absolute;
      content: '';
      width: 50px;
      height: 4px;
      background: linear-gradient(to right, #aa20ff 0%, #ff14c1 100%);
      border-radius: 1.25em;
      bottom: 0;
      margin-bottom: -1rem;
      right: calc(50% - 30px);
    }
  }
  p {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 2.25rem;
    margin-top: 2.75rem;
    margin-bottom: 2.125rem;
  }

  .stacked {
    display: grid;
    z-index: 100;
  }
  .circles-grid {
    display: grid;
    grid-template-columns: repeat(38, 1fr);
    grid-template-rows: 2fr 1fr 2fr 1fr 2fr;
    row-gap: 20px;
    width: 100%;
    font-size: 20px;
    line-height: 24px;
    z-index: 0;
    margin-top: 110px;
    @media only screen and (max-width: 1124px) {
      grid-template-rows: 2fr 1fr 1.5fr 1fr 2fr;
      font-size: 18px;
      margin-top: 80px;
    }
    @media only screen and (max-width: 991px) {
      grid-template-rows: 2fr 1fr 1.25fr 1fr 2fr;
      font-size: 14px;
      line-height: 20px;
    }
    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(48, 1fr);
      margin: 150px 0 200px;
    }
    @media only screen and (max-width: 441px) {
      row-gap: 15px;
    }

    .circle {
      color: white;
      font-weight: 400;
      font-size: 24px;

      grid-column: span 6;
      grid-row: 3 / 4;
      align-self: center;
      position: relative;
      @media only screen and (max-width: 991px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 24px;
        grid-column: 4 / 10;
        grid-row: span 8;
      }
      span {
        z-index: 10;
      }
      img {
        position: absolute;
        margin: auto;
        inset: 0;
        z-index: -1;
      }
    }
    .arrow {
      color: white;
      grid-column: span 2;
      grid-row: 3 / 4;
      display: grid;
      place-content: center;
      position: relative;
      @media only screen and (max-width: 768px) {
        grid-column: 4 / 10;
        grid-row: span 2;
        // background-color: rgba(127, 255, 212, 0.445);
      }
    }
    @media only screen and (max-width: 768px) {
      .empathize {
        grid-row: 1 / span 8;
      }
      .arrow-empathize {
        grid-row: 9 / span 2;
      }
      .define {
        grid-row: 11 / span 8;
      }
      .arrow-define {
        grid-row: 19 / span 2;
      }
      .ideate {
        grid-row: 21 / span 8;
      }
      .arrow-ideate {
        grid-row: 29 / span 2;
      }
      .prototype {
        grid-row: 31 / span 8;
      }
      .arrow-prototype {
        grid-row: 39 / span 2;
      }
      .test {
        grid-row: 41 / span 8;
      }
    }

    .learn-from-users {
      grid-column: 3 / 36;
      grid-row: 1 / 3;
      padding-bottom: 4rem;

      display: grid;
      align-content: start;
      padding-top: 2.5rem;
      position: relative;
      @media only screen and (max-width: 768px) {
        grid-column: 9 / 12;
        grid-row: 3 / 46;

        padding-top: unset;
        padding-bottom: unset;
        border-top: 2px dashed $purple;
        border-right: 2px dashed $purple;
        border-bottom: 2px dashed $purple;
        z-index: -2;
        &::before {
          content: '';
          position: absolute;
          --size: 0.8em;
          z-index: -1;

          left: -2%;
          top: -1.2%;

          width: 1.3rem;
          height: 2rem;
          background-color: white;
          @media only screen and (max-width: 475px) {
            top: -1.4%;
            left: 20%;
          }
          @media only screen and (max-width: 400px) {
            left: 10%;
          }
        }
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-radius: 0.25rem;
          --size: 0.8em;

          left: 2%;
          top: -1.2%;

          border-right: 14px solid $purple;
          border-bottom: var(--size) solid transparent;
          border-top: var(--size) solid transparent;
          @media only screen and (max-width: 475px) {
            top: -1%;
            left: 25%;
          }
        }
      }
      span {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      img {
        position: absolute;
        margin: auto;
        padding-bottom: 3rem;
        inset: 0;
        z-index: -1;
        @media only screen and (max-width: 1124px) {
          padding-bottom: 1rem;
        }
        @media only screen and (max-width: 991px) {
          padding-bottom: 0rem;
        }
        @media only screen and (max-width: 768px) {
          margin: auto auto auto 0;
          display: none;
        }
      }
    }
    .learn-from-users-mobile-text {
      display: none;
      @media only screen and (max-width: 768px) {
        display: grid;
        place-content: center;
        z-index: 100;
        grid-column: 9 / 12;
        grid-row: 18 / span 4;
        color: $purple;
        font-size: 13px;
        line-height: 18px;
      }
      @media only screen and (max-width: 400px) {
        grid-column: 8 / 12;
      }
    }

    .research {
      grid-column: 4 / 13;
      grid-row: 2 / 3;

      display: grid;
      align-content: end;
      position: relative;
      z-index: 1000;
      @media only screen and (max-width: 768px) {
        grid-column: 9 / 11;
        grid-row: 4 / 16;
        // background-color: cadetblue;
        z-index: -1;
        border-top: 2px dashed $purple;
        border-right: 2px dashed $purple;
        border-bottom: 2px dashed $purple;
        z-index: -2;
        &::before {
          content: '';
          position: absolute;
          --size: 0.8em;
          z-index: -1;

          left: 0%;
          bottom: -1.2%;

          width: 1.3rem;
          height: 2rem;
          background-color: white;
          @media only screen and (max-width: 475px) {
            left: 40%;
            bottom: -4.5%;
          }
        }
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-radius: 0.25rem;
          --size: 0.8em;

          left: 10%;
          bottom: -4.5%;

          border-right: 14px solid $purple;
          border-bottom: var(--size) solid transparent;
          border-top: var(--size) solid transparent;
          @media only screen and (max-width: 475px) {
            left: 50%;
            bottom: -4.5%;
          }
        }
      }
      span {
        position: absolute;
        margin: auto;
        padding-top: 1rem;
        inset: 0;
        @media only screen and (max-width: 991px) {
          padding-top: 0.35rem;
          display: none;
        }
      }
      img {
        display: none;
        position: absolute;
        margin: auto;
        padding-bottom: 1rem;
        inset: 0;
        z-index: -1;
      }
    }
    .research-mobile-text {
      display: none;
      @media only screen and (max-width: 768px) {
        display: grid;
        place-content: center;
        z-index: 100;
        grid-column: 8 / 11;
        grid-row: 8 / span 4;
        color: $purple;
        font-size: 13px;
        line-height: 18px;
      }
      @media only screen and (max-width: 400px) {
        grid-column: 7 / 11;
      }
    }

    .try {
      grid-column: 20 / 35;
      grid-row: 2 / 3;

      padding-top: 2rem;

      display: grid;
      align-content: center;
      position: relative;
      z-index: 1000;
      @media only screen and (max-width: 768px) {
        grid-column: 9 / 11;
        grid-row: 24 / 45;
        z-index: -1;
        border-top: 2px dashed $purple;
        border-right: 2px dashed $purple;
        border-bottom: 2px dashed $purple;
        z-index: -2;
        &::before {
          content: '';
          position: absolute;
          --size: 0.8em;
          z-index: -1;

          left: 0%;
          top: -3%;

          width: 1.3rem;
          height: 2rem;
          background-color: white;
          @media only screen and (max-width: 475px) {
            top: -2.5%;
            left: 35%;
          }
        }
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-radius: 0.25rem;
          --size: 0.8em;

          left: 10%;
          top: -2.3%;

          border-right: 14px solid $purple;
          border-bottom: var(--size) solid transparent;
          border-top: var(--size) solid transparent;
          @media only screen and (max-width: 475px) {
            top: -2%;
            left: 52%;
          }
        }
      }
      span {
        position: absolute;
        margin: auto;
        padding-top: 0.4rem;
        inset: 0;
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      img {
        position: absolute;
        margin: auto;
        padding-bottom: 2.5rem;
        inset: 0;
        z-index: -1;
        @media only screen and (max-width: 991px) {
          padding-bottom: 1.5rem;
        }
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    .try-mobile-text {
      display: none;
      @media only screen and (max-width: 768px) {
        display: grid;
        place-content: center;
        z-index: 100;
        grid-column: 8 / 11;
        grid-row: 38 / span 4;
        color: $purple;
        font-size: 13px;
        line-height: 18px;
      }
    }

    .learn-from-prototype {
      grid-column: 20 / 28;
      grid-row: 4 / 5;

      position: relative;
      z-index: 1000;
      @media only screen and (max-width: 768px) {
        grid-column: 3 / 5;
        grid-row: 24 / 36;
        z-index: 0;
        border-top: 2px dashed $purple;
        border-left: 2px dashed $purple;
        border-bottom: 2px dashed $purple;
        z-index: -2;
        &::before {
          content: '';
          position: absolute;
          --size: 0.8em;
          z-index: -1;

          right: 0%;
          top: -5%;

          width: 1.3rem;
          height: 2rem;
          background-color: white;
          @media only screen and (max-width: 475px) {
            top: -5%;
            left: 30%;
          }
        }
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-radius: 0.25rem;
          --size: 0.8em;

          right: 6%;
          top: -4%;

          border-left: 14px solid $purple;
          border-bottom: var(--size) solid transparent;
          border-top: var(--size) solid transparent;
          @media only screen and (max-width: 475px) {
            top: -4%;
            left: 25%;
          }
        }
      }
      span {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      img {
        position: absolute;
        margin: auto;
        padding-top: 1.5rem;
        inset: 0;
        z-index: -1;
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    .learn-from-prototype-mobile-text {
      display: none;
      @media only screen and (max-width: 768px) {
        display: grid;
        place-content: center;
        z-index: 100;
        grid-column: 4 / 6;
        grid-row: 28 / span 4;
        color: $purple;
        font-size: 13px;
        line-height: 18px;
      }
      @media only screen and (max-width: 475px) {
        grid-column: 3 / 6;
      }
      @media only screen and (max-width: 441px) {
        grid-column: 3 / 7;
      }
    }

    .testing {
      grid-column: 12 / 36;
      grid-row: 4 / 6;
      padding-bottom: 4rem;

      display: grid;
      align-content: end;
      position: relative;

      @media only screen and (max-width: 768px) {
        grid-column: 2 / 5;
        grid-row: 15 / 46;
        z-index: -1;
        border-top: 2px dashed $purple;
        border-left: 2px dashed $purple;
        border-bottom: 2px dashed $purple;
        z-index: -2;
        &::before {
          content: '';
          position: absolute;
          --size: 0.8em;
          z-index: -1;

          right: 0%;
          top: -3%;

          width: 1.3rem;
          height: 2rem;
          background-color: white;
          @media only screen and (max-width: 475px) {
            top: -1.5%;
            right: 30%;
          }
        }
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-radius: 0.25rem;
          --size: 0.8em;

          right: 6%;
          top: -1.6%;

          border-left: 14px solid $purple;
          border-bottom: var(--size) solid white;
          border-top: var(--size) solid white;
          @media only screen and (max-width: 475px) {
            top: -1.5%;
            right: 32%;
          }
        }
      }
      span {
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      img {
        position: absolute;
        margin: auto;
        padding-top: 0rem;
        padding-bottom: 2rem;
        inset: 0;
        z-index: -1;
        @media only screen and (max-width: 991px) {
          padding-bottom: 2.75rem;
        }
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    .testing-mobile-text {
      display: none;
      @media only screen and (max-width: 768px) {
        display: grid;
        place-content: center;
        z-index: 100;
        grid-column: 2 / 5;
        grid-row: 37 / span 6;
        color: $purple;
        font-size: 13px;
        line-height: 18px;
      }
      @media only screen and (max-width: 768px) {
        grid-column: 2 / 6;
      }
    }
  }
}
