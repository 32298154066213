.software_software {
  margin-top: 8.125rem;
  margin-bottom: 0;

  .container {
    padding-bottom: 4.75rem;
  }

  .wrapper {
    margin-top: 100px;
    background: $gray4;
    border-radius: 2.5rem 2.5rem 0.625rem 2.5rem;
    padding: 2.25rem 1.875rem 3.125rem 1.875rem;

    .description {
      &__text {
        &--title {
          display: flex;
          align-items: center;
          span {
            width: 40px;
            height: 30px;
            text-align: center;
            margin-inline-end: 20px;
          }
          h3 {
            margin-bottom: 0;
            font-size: 1.25rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .description__img {
    position: relative;
    img {
      position: absolute;
      top: -65px;
    }
  }

  .web {
    display: flex;
    gap: 0.5rem;
    align-items: stretch;
    justify-content: space-between;
    text-align: left;

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  h2 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 1.25rem;
    margin-bottom: 1.0625rem;
  }

  .icon {
    align-self: center;
    margin-top: 4rem;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .icon-mobile {
    display: none;
    @media only screen and (max-width: 768px) {
      margin-top: 1.875rem;
      text-align: center;
      display: block;
    }
  }

  .web-card {
    height: 90%;
  }
  .cards {
    border: 1px dashed $purple;
    font-size: 1rem;
    padding: 1.25rem 1.25rem 1rem 1.25rem;
    border-radius: 0.625rem;
    min-width: 280px;
    min-height: 300px;
    @media only screen and (max-width: 420px) {
      min-width: unset;
    }

    h4 {
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 0.625rem;

      &:not(:first-child) {
        margin-top: 1.25rem;
      }
    }
    p {
      line-height: 1.625rem;
      margin-bottom: 0;
    }
  }

  .row {
    gap: 30px;
  }

  @media only screen and (max-width: 1034px) {
    .description__img {
      img {
        left: -5%;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .description__img {
      img {
        left: -70px;
      }
    }
  }
  @media only screen and (max-width: 870px) {
    .description__img {
      display: flex;
      margin-bottom: 3rem;
      justify-content: center;
      img {
        position: relative;
        left: auto;
        top: auto;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .description {
      flex-direction: column;
    }
    .description__img {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .line {
    width: 100%;
    border-bottom: 2px dashed $purple;
  }
}
