.cfdis_guard {
  @media only screen and (max-width: 475px) {
    margin-bottom: 9.375rem;
  }
  .services {
    display: flex;
    flex-flow: column wrap;
    @media only screen and (max-width: 1124px) {
      &:first-child {
        margin-bottom: 72px;
      }
    }
  }
  .logo {
    width: 138px;
    height: 28px;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
      align-self: center;
    }
  }
  .cards-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .card {
    border: 1px dashed $purple;
    max-width: 500px;
    border-radius: 10px;
    padding: 20px;
    @media only screen and (max-width: 1124px) {
      max-width: 100%;
    }
    h5 {
      font-size: 1rem;
      // line-height: 2.25rem;
      font-weight: bold;
      margin-bottom: 10px;
    }
    p {
      font-size: 1rem;
      line-height: 2.25rem;
    }

    .keep-reading {
      font-weight: bold;
      margin-bottom: 0;
      text-decoration: none;
      color: inherit;
      .icon {
        margin-left: 0.625rem;
      }
    }
  }

  .showcase {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
