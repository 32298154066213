.software_phases_services {
  color: $purple;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 36px;
  margin-top: 0;
  position: relative;

  &__text {
    span {
      color: $pink;
      font-weight: 500;
      font-style: italic;
    }
    @media only screen and (max-width: 475px) {
      text-align: center;
    }
  }

  .container {
    padding-top: 5.625rem;
  }

  a {
    color: inherit;
  }

  .margin {
    margin-top: 2.3125rem;
    margin-bottom: 6.25rem;
    padding-top: 1.25rem;

    @media only screen and (max-width: 475px) {
      margin-top: 3.5625rem;
      margin-bottom: 3.125rem;
    }
  }

  .bold {
    font-weight: bold;
  }

  .cards {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 1.75rem;
    height: 100%;
    position: relative;

    @media only screen and (max-width: 576px) {
      flex-flow: column wrap;
      align-items: center;
      overflow-x: hidden;
      margin-top: 8.075rem;
      gap: 4.95rem;
      padding: 0 0.75rem;
    }
    @media only screen and (max-width: 475px) {
      display: block;
      margin-top: 3rem;
    }

    .mobile-hidden {
      @media only screen and (max-width: 576px) {
        display: none;
      }
    }

    .card {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4.375rem 1.25rem 1.875rem 1.25rem;
      // background: linear-gradient(
      //   210deg,
      //   #fdfdfd 0%,
      //   #fdfdfd 20%,
      //   #d8efff 45%,
      //   #fff 50%,
      //   #f1f7fa 100%
      // );
      background: linear-gradient(220deg, rgba(255, 255, 255, 0.6) 0%, #f1f7fa 100%);
      border-radius: 2.5rem 2.5rem 2.5rem 6.25rem;
      border: none;
      min-width: 320px;
      position: relative;
      z-index: 5;
      margin-top: 3.5rem;
      // background-size: 350%;
      height: 100%;
      // transition: background-position 350ms;

      // &:hover {
      //   background-position: bottom right;
      // }

      &__icon {
        margin-top: -1.3rem;
        position: absolute;
        top: 0;
        z-index: 10000;
        overflow-y: visible;

        &::before {
          content: '';
          display: inline-block;
          width: 100px;
          height: 100px;
          border-radius: 4rem;
          bottom: -29px;
          left: calc(50% - 49px);
          position: absolute;
          background-color: $gray2;
          z-index: -1;
        }
      }

      &__description {
        font-weight: 400;
        margin: 20px 0 14px 0;
        text-align: center;
      }
    }
  }

  @mixin white-gradient {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &::before,
  &::after {
    @include white-gradient;
    content: '';
    height: 60%;
    margin: 20rem auto auto auto;
    position: absolute;
    width: 140px;
    z-index: 10;
    pointer-events: none;
    @media only screen and (max-width: 992px) {
      margin: 23rem auto auto auto;
    }
    @media only screen and (max-width: 767px) {
      height: 50%;
      margin: 30rem auto auto auto;
    }
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  $animationSpeed: 30s;

  // Animation
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-348px * 6));
    }
  }

  // Styling
  .slider {
    height: calc(100% + 200px);
    margin: 90px auto auto auto;
    overflow: hidden;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 478px) {
      margin-bottom: 85px;
      margin: 0 auto;
    }

    .slide-track {
      animation: scroll $animationSpeed linear infinite;
      display: flex;
      width: calc(348px * 12);
      height: calc(100% + 200px);
      @media only screen and (max-width: 576px) {
        animation: none;
        display: flex;
        justify-content: center;
        width: 100%;
      }

      &:hover {
        animation-play-state: paused;
      }
    }

    .slide {
      height: calc(100% + 200px);
      max-width: 320px;
    }
  }
}
