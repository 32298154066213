.logo {
  z-index: 10000;
}

.dropdown {
  position: relative;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: color 200ms ease-in;
  z-index: 1000;

  &:hover,
  &:active {
    color: rgba(241, 247, 250, 0.5) !important;
  }
  &.-dark {
    color: $purple !important;
    &:hover,
    &:active {
      color: rgba(75, 33, 146, 0.7) !important;
    }
  }
}

.navbar_nomi {
  .dropdown-toggle::after {
    content: none;
  }

  .nav-dark a {
    color: $purple !important;
    &:hover,
    &:active {
      color: rgba(75, 33, 146, 0.7) !important;
    }
  }

  // .dropdown {
  //   position: relative;
  // }

  .dropdown-menu {
    background-color: rgba(241, 247, 250, 0.95);
    border-radius: 20px;
    position: absolute;
    left: -14%;
    top: 100%;
    display: flex;
    flex-direction: column;
    min-width: 12rem;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    transition: opacity 300ms ease-in-out 100ms,
      transform 300ms ease-in-out 100ms;
    @media only screen and (max-width: 991px) {
      left: -50%;
    }

    &::before {
      --size: 0.4em;
      content: "";
      position: absolute;
      width: 0;
      height: 0;

      left: 44%;
      top: -2.8%;

      border-left: var(--size) solid transparent;
      border-right: var(--size) solid transparent;
      border-bottom: var(--size) solid rgba(241, 247, 250, 0.95);
    }

    a {
      color: $purple1 !important;
      font-size: 1rem;
      line-height: 2.375rem;
      padding: 15px;
      font-weight: 400;
      &:hover {
        background: transparent;
      }
      &.nav-dark {
        color: $purple !important;
        &:hover,
        &:active {
          color: rgba(75, 33, 146, 0.7) !important;
        }
      }
    }
    a:last-child {
      border-bottom: none;
    }
  }

  .dropdown:hover .dropdown-menu,
  .dropdown-menu:hover {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    margin-top: 0;
  }

  .dropdown1 .dropdown-menu {
    margin-left: -43%;

    @media only screen and (max-width: 991px) {
      margin-left: -33%;
    }
  }

  .dropdown2 .dropdown-menu {
    margin-left: -30%;
    @media only screen and (max-width: 991px) {
      margin-left: -15%;
    }
  }
  .dropdown-item {
    color: #521798;
    padding: 0.9375rem;
  }
  .dropdown-item:hover {
    background: #dbc0fb !important;
  }
  .dropdown-item:first-child {
    border-radius: 20px 20px 0 0;
  }
  .dropdown-item:last-child {
    border-radius: 0 0 20px 20px;
  }
  .dropdown-item:nth-child(2) {
    border-top: 1px solid purple;
    border-bottom: 1px solid purple;
  }

  a {
    font-size: 1rem;
    line-height: 2.375rem;
    font-weight: bold;
    text-decoration: none;
    padding: 8px;
  }
  .nav-link {
    font-size: 1rem;
    line-height: 2.375rem;
    color: $white !important;
    font-weight: bold;
    &:hover,
    &:active {
      color: rgba(241, 247, 250, 0.5) !important;
    }
    &.nav-dark {
      color: $purple !important;
      &:hover,
      &:active {
        color: rgba(75, 33, 146, 0.7) !important;
      }
    }
  }

  .dropdown-menu[data-bs-popper] {
    margin-top: 0;
  }

  .nav-btn {
    padding: 0.3125em 1.1875em;
    border-radius: 2.25em;
    margin: 0;
    background-image: linear-gradient(
      120deg,
      white 0%,
      white 50%,
      #f62fc0 55%,
      #f62fc0 80%,
      #92f 100%
    );
    color: $purple !important;
    background-size: 350%;
    transition: background-position 350ms;

    @media only screen and (max-width: 992px) {
      margin-bottom: 2rem;
      margin-top: 1.125rem;
      margin-right: 0;
    }

    &:hover,
    &:active {
      background-position: right;
      color: white !important;
    }

    &.nav-dark {
      color: $white !important;
      background-image: linear-gradient(
        120deg,
        $purple 0%,
        $purple 50%,
        #f62fc0 55%,
        #f62fc0 80%,
        #92f 100%
      );
    }
  }

  .me-1 {
    margin-inline-end: 0.675rem !important;
  }

  .jcs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .navbar-toggler {
    border: none;
    padding: 0;
  }
  .navbar-toggler-icon {
    background-image: url("../assets/svg/icon-ham.svg") !important;
  }

  .nav-dark .navbar-toggler-icon {
    background-image: url("../assets/svg/icon-ham-dark.svg") !important;
  }

  @media (max-width: 991px) {
    .container {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .logo img {
      height: 45px;
      width: 43px;
    }
  }

  .nav-lang {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    a {
      font-weight: 400;
    }
    .dropdown-item {
      color: #521798;
      padding: 0.9375rem;
      background-color: rgba(241, 247, 250, 0.95);
    }
    .dropdown-item:hover {
      background: #dbc0fb !important;
    }
    .dropdown-item:first-child {
      border-radius: 20px 20px 0 0;
    }
    .dropdown-item:last-child {
      border-radius: 0 0 20px 20px;
    }
    .dropdown-item:nth-child(2),
    .dropdown-item:nth-child(3) {
      border-bottom: 1px solid purple;
    }
  }

  .dropdown:hover .icon_rotate_hover .rotate-prueba {
    transform: rotate(-180deg);
  }

  .dropdown-links {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 0.75rem 0;
    margin-left: -1.5rem;
    color: black;
  }

  .dropdown-lang {
    border-radius: 20px;
    position: absolute;
    left: 0%;
    top: 100%;
    display: flex;
    flex-direction: column;
    min-width: 5rem;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    transition: opacity 300ms ease-in-out 100ms,
      transform 300ms ease-in-out 100ms;
  }
  .dropdown:hover .dropdown-lang,
  .dropdown-lang:hover {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    margin-top: 0;
  }

  #responsive-navbar-nav {
    display: none;
  }
}

.modal-header {
  border-bottom: none;
  padding: 25px 12px;
  .btn-close {
    width: 2.5rem;
    height: 2.5rem;
    background: 2em auto no-repeat !important;
  }
}

.modal-body {
  padding: 0;
  margin-top: 3.125rem;
  @media only screen and (min-width: 991px) {
    margin-top: 8rem;
  }
}

.modal-content {
  background-color: rgba(133, 19, 184, 0.78);

  p {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin: auto 0;
    min-height: 3rem;
  }
  a {
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    color: white;
    text-decoration: none;
    z-index: 1000;
    transition: color 120ms ease-in;
    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .dropdown-lang {
    border-radius: 20px;
    position: absolute;
    left: 1%;
    top: 100%;
    display: flex;
    flex-direction: column;
    min-width: 5rem;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    transition: opacity 300ms ease-in-out 100ms,
      transform 300ms ease-in-out 100ms;
  }
  .dropdown:hover .dropdown-lang,
  .dropdown-lang:hover {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    margin-top: 0;
  }
  .dropdown-links {
    padding: 0.75rem 0;
  }
  .dropdown-item {
    color: white;
    font-weight: bold;
  }
}
