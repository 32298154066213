.software_architecture {
  margin-top: 8.125rem;
  margin-bottom: 0;

  .img-architecture {
    text-align: center;
  }
  .logos {
    width: 9.375rem;
    div {
      border: 1px solid $gray7;
      display: flex;
      justify-content: center;
    }
  }

  $animationSpeed: 25s;

  // Animation
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-200px * 12));
    }
  }

  // Styling
  .slider {
    background: white;
    height: 150px;
    margin: 90px auto auto auto;
    overflow: hidden;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 478px) {
      margin-bottom: 85px;
    }

    .slide-track {
      animation: scroll $animationSpeed linear infinite;
      display: flex;
      width: calc(200px * 24);
    }

    .slide {
      height: 150px;
      width: 200px;
    }
  }
}
