.cfdis_hero {
  height: 1547px;
  position: relative;
  margin-bottom: -400px;
  @media only screen and (max-width: 1100px) {
    margin-bottom: -500px;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: -600px;
  }
  @media only screen and (max-width: 610px) {
    margin-bottom: -650px;
  }
  @media only screen and (max-width: 476px) {
    height: 600px;
    margin-bottom: 200px;
  }
  @media only screen and (max-width: 410px) {
    margin-bottom: 100px;
  }

  .hero-text {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    @media only screen and (max-width: 991px) {
      margin-top: 45px;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 50px;
    }
    @media only screen and (max-width: 476px) {
      max-width: 90%;
      margin: 50px auto 0 auto;
    }
    h1 {
      font-size: 2.875rem;
      font-weight: 300;
      line-height: 3.75rem;
      color: $white;
      text-align: center;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 768px) {
        width: 85%;
      }
      @media only screen and (max-width: 476px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
        width: 65%;
      }
      @media only screen and (max-width: 411px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
        width: 85%;
      }
    }

    .sub-title {
      font-size: 3.125rem;
      font-weight: bold;
      margin-bottom: 0;
      @media only screen and (max-width: 476px) {
        font-size: 1.5rem;
      }
    }
  }

  .phones-img {
    margin-top: 74px;
    padding: 0 12px;
    max-width: 100%;
    height: auto;
    display: inline-block;
  }

  .referenceAnimationBox {
    width: 100%;
    height: 40%;
    // background-color: thistle;
    position: absolute;
    bottom: 0;
    margin-bottom: 0px;
    z-index: 5;
    @media only screen and (min-width: 2560px) {
      margin-bottom: -280px;
      bottom: -150px;
    }
    @media only screen and (max-width: 1024px) {
      bottom: 80px;
    }
    @media only screen and (max-width: 768px) {
      bottom: 140px;
    }
    @media only screen and (max-width: 475px) {
      display: none;
    }
  }
  .hide-phone {
    display: block;
    @media only screen and (max-width: 475px) {
      display: none;
    }
  }
}
