.payroll_maquila_return-to-services {
  display: inline-block;

  .btn-return {
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    color: $purple;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 50px;
    z-index: 1000;
    position: relative;

    &:hover {
      color: $purple2;
    }

    div {
      margin-left: 0.625rem;
    }
  }
}
