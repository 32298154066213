.software_strategy {
  margin-top: 0;

  .container {
    padding-top: 8.125rem;
    padding-bottom: 4.75rem;
    @media only screen and (max-width: 475px) {
      padding-top: 0;
    }
  }

  .margin {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .cards {
    display: grid;
    grid-gap: 5.625rem;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    justify-items: center;
    text-align: left;
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;

    @media only screen and (max-width: 1200px) {
      grid-gap: 1.5rem;
    }

    @media only screen and (max-width: 991px) {
      grid-gap: 1.2rem;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
    }

    @media only screen and (max-width: 768px) {
      grid-gap: 3.125rem;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
    @media only screen and (max-width: 475px) {
      margin-bottom: 0;
    }

    .card {
      border: 1px dashed $purple;
      border-radius: 0.75rem;
      font-size: 1rem;
      height: 100%;
      background: none;
      padding: 1.875rem 1.25rem 1.6875rem 1.25rem;

      &__title {
        font-weight: bold;
        font-size: 1.25rem;
        margin: 1.25rem 0 0.625rem 0;
      }
    }
  }

  .line {
    width: 100%;
    border-bottom: 2px dashed $purple;
  }
}
