.software_ux {
  padding-top: 8.125rem;
  margin-bottom: 0;
  margin-top: 105px;
  background-color: $gray6;
  @media only screen and (max-width: 475px) {
    margin-top: 76px;
  }
  .container {
    position: relative;
  }
  .lines-to-from {
    position: absolute;
    top: 200px;
    left: 18px;
  }
  .cards-wrapper {
    margin-top: 99px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media only screen and (max-width: 768px) {
      display: block;
      margin: 99px auto 0 auto;
    }
  }
  .card {
    flex: 1 1 300px;
    max-width: 300px;
    text-align: center;
    line-height: 26px;
    border: none;
    min-width: 280px;
    background-color: transparent;
    @media only screen and (max-width: 991px) {
      &:last-child {
        margin: 50px auto 0 auto;
      }
    }
    @media only screen and (max-width: 768px) {
      flex: unset;
      min-width: unset;
      margin: 50px auto 0 auto;
    }
    @media only screen and (max-width: 475px) {
      margin: 50px auto 0 auto;
      max-height: 100%;
    }
    .icon {
      width: 80px;
      margin: 0 auto 20px auto;
    }
    &__title {
      margin-bottom: 20px;
    }
    &__line {
      margin-bottom: 20px;
    }
  }

  .line {
    margin-top: 75px;
    border-bottom: 2px dashed $purple;
  }
}
