.my_company_rules {
  h2 {
    font-size: 3.125rem;
    font-weight: bold;
    line-height: 3.75rem;
    margin-bottom: 1rem;
    @media only screen and (max-width: 475px) {
      font-size: 30px;
      line-height: 50px;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 2.25rem;
  }

  .img-line {
    margin-bottom: 50px;
  }

  .description {
    margin-bottom: 50px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    gap: 7%;
    @media only screen and (max-width: 992px) {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 60px;
    }
    &__img {
      max-width: 548px;
      @media only screen and (max-width: 576px) {
        max-width: 100%;
      }
    }
    &__detail {
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 2.25rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      @media only screen and (max-width: 911px) {
        justify-content: center;
        align-items: center;
        gap: 40px;
      }
      &__btn {
        // background-color: blue;
      }
    }
    @media (max-width: 475px) {
      .button-nomi div {
        width: 100% !important;
      }
    }
  }
}
