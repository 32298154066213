.my_company_hero {
  height: 900px;
  position: relative;
  @media only screen and (max-width: 475px) {
    height: 600px;
  }
  &__bg {
    position: absolute;
    z-index: 2;
    max-height: 587px;
    max-width: 1237px;
    background: center url(../assets/img/hero-d.png);
    background-size: contain;
    background-repeat: no-repeat;
    inset: 0;
    margin: 12% auto 0 auto;
    overflow: hidden;
    pointer-events: none;

    @media only screen and (max-width: 1200px) {
      margin: 15% auto 0 auto;
    }
    @media only screen and (max-width: 1100px) {
      margin: 20% auto 0 auto;
    }
    @media only screen and (max-width: 991px) {
      margin: 35% auto 0 auto;
    }
    @media only screen and (max-width: 820px) {
      margin: 40% auto 0 auto;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .layout-title {
    margin-top: 70px;
    width: 62%;
    z-index: 80;
  }
  h1 {
    font-size: 2.875rem;
    font-weight: 400;
    line-height: 3.75rem;
    color: $white;
  }
  .sub-title {
    font-size: 3.125rem;
    font-weight: bold;
    @media only screen and (max-width: 475px) {
      font-size: 24px !important;
    }
  }

  @media only screen and (max-width: 1200px) {
    h1 {
      font-size: 2.575rem;
      line-height: 2.75rem;
    }
    .sub-title {
      font-size: 2.925rem;
    }
  }

  @media only screen and (max-width: 991px) {
    h1 {
      font-size: 2.375rem;
      line-height: 2.75rem;
    }
    .sub-title {
      font-size: 2.725rem;
    }
    .layout-title {
      width: 80%;
    }
  }
  @media only screen and (max-width: 991px) {
    .layout-title {
      width: 100%;
      text-align: center;
      padding-top: 45px;
    }
  }
  @media only screen and (max-width: 475px) {
    .layout-title {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: 100%;
    }
    h1 {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
    .sub-title {
      font-size: 1.5rem;
    }
  }
}
