.integrity_welcome {
  font-size: 1.25rem;
  margin-top: 3.0625rem;
  position: relative;

  .text-center {
    text-align: center;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .title {
    font-size: 1.875rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 0.625rem;
    }

    &__logo {
      display: flex;
    }
  }

  .description {
    font-weight: bold;
    line-height: 2.25rem;
    margin-top: 3.125rem;
    margin-bottom: 0;
    @media only screen and (max-width: 475px) {
      text-align: center;
    }
  }

  .line-purple {
    margin-top: 6.25rem;
    margin-bottom: 3.125rem;
    height: 4px;
    width: 100%;
    background: linear-gradient(#8307fe 0%, #ea07ad 100%);
  }

  .modul-showcase {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin: 1.25rem auto 3.125rem auto;
    max-width: 1304px;

    .asterisk {
      margin-top: -1.25rem;
      text-align: center;
      color: #b4b4b4;
      font-weight: bold;
      font-size: 0.875rem;
      @media only screen and (max-width: 1080px) {
        margin-top: 0rem;
      }
      @media only screen and (max-width: 475px) {
        font-size: 0.75rem;
      }
    }
  }

  .modul-description {
    text-align: center;
    padding: 7.5625rem 0 9.875rem 0;
    margin-top: -6.5rem;
    background: linear-gradient(#fff 0%, #f1f7fa 100%);

    &__dropdown-menu {
      display: flex;
      justify-content: center;
      position: relative;

      select {
        font-size: 0.875rem;
        font-weight: bold;
        padding: 0.5rem 7.8125rem 0.5rem 1.25rem;
        color: $white;
        background: $purple;
        border: none;
        border-radius: 2.5rem;
        cursor: pointer;
      }

      .custom-select {
        position: relative;
      }

      .custom-arrow {
        display: block;
        top: 0;
        right: 0;
        background: $purple;
        border-radius: 5rem;
        height: 100%;
        width: 1.5625rem;
        position: absolute;
        pointer-events: none;

        &::after {
          --size: 0.3em;
          content: '';
          position: absolute;
          width: 0;
          height: 0;

          left: 5%;
          top: 40%;

          border-left: var(--size) solid transparent;
          border-right: var(--size) solid transparent;
          border-top: var(--size) solid $white;
        }
      }
    }

    &__body {
      margin-top: 1.875rem;
      line-height: 2.25rem;
      font-size: 1.25rem;
    }
  }
}
