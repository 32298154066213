.dicoma_checkin {
  position: relative;

  @media only screen and (max-width: 475px) {
    margin-top: 6rem;
  }

  h3 {
    font-weight: 400;
    font-size: 1.875rem;
  }

  h3,
  p {
    margin: 0;
  }

  .wrapper {
    position: relative;

    .title {
      line-height: 60px;
      font-size: 3.125rem;
      width: 45%;
      position: relative;
      margin-bottom: 4.375rem;
      font-weight: bold;

      @media only screen and (max-width: 991px) {
        width: 100%;
      }
      @media only screen and (max-width: 475px) {
        font-size: 1.875rem;
        line-height: 50px;
        margin-bottom: 5.375rem;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        margin-top: 1.675rem;
        background: $blue1;
        height: 4px;
        width: 65px;
        border-radius: 2.5rem;
        left: 0;
      }
    }

    .description {
      font-size: 1.25rem;
      font-weight: 300;
      line-height: 2.25rem;
      width: 45%;

      @media only screen and (max-width: 991px) {
        width: 100%;
      }
    }

    .illust {
      position: absolute;
      right: 0;
      margin-top: -25rem;

      @media only screen and (max-width: 1080px) {
        margin-right: 0;
      }

      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  .cards {
    display: grid;
    gap: 1.6875rem;
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
    justify-items: center;
    text-align: center;
    margin-top: 8.125rem;

    @media only screen and (max-width: 991px) {
      grid-gap: 1.2rem;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      margin-top: 4.5rem;
    }

    @media only screen and (max-width: 475px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }

    .card {
      border: none;
      height: 100%;
      background: none;

      &--img {
        @media only screen and (max-width: 475px) {
          display: none;
        }
      }

      &--img-mobile {
        display: none;
        @media only screen and (max-width: 475px) {
          display: block;
        }
      }

      &--title {
        margin-top: 1.8125rem;
        margin-bottom: 1.125rem;
      }

      &--description {
        font-size: 1.1875rem;
        line-height: 2.25rem;
        font-weight: 300;
      }
    }
  }

  .blue-line {
    width: 100%;
    height: 34px;
    border-left: 2px solid $blue1;
    border-right: 2px solid $blue1;
    border-bottom: 2px solid $blue1;
    margin-top: 1rem;
    position: relative;

    @media only screen and (max-width: 475px) {
      display: none;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      height: 70px;
      margin: 2.1rem 35.9% auto auto;
      inset: 0;
      background: no-repeat url(../assets/check-in/blue-arrow.svg);

      @media only screen and (max-width: 1200px) {
        margin: 2.1rem 43% auto auto;
      }
      @media only screen and (max-width: 991px) {
        margin: 2.1rem 84% auto auto;
      }
      @media only screen and (max-width: 768px) {
        margin: 2.1rem 87% auto auto;
      }
      @media only screen and (max-width: 475px) {
        display: none;
      }
    }
  }

  .data {
    display: flex;
    justify-content: space-between;
    margin-top: 2.1875rem;
    gap: 4.375rem;
    flex-wrap: wrap-reverse;
    margin-bottom: 6.25rem;

    @media only screen and (max-width: 475px) {
      gap: 2.375rem;
      margin-bottom: 4rem;
    }

    .its-fast {
      flex-basis: 46%;
      color: $black;

      @media only screen and (max-width: 1200px) {
        flex-basis: 37%;
      }

      @media only screen and (max-width: 991px) {
        flex-basis: 100%;
      }

      &__title {
        margin-bottom: 0.5rem;
      }

      &__quote {
        font-size: 1.25rem;
        font-weight: 300;
        font-style: italic;
      }
    }

    .yellow-rectangle {
      background: $yellow;
      border-radius: 0.625rem;
      padding: 2rem 2.5rem 2rem 3.75rem;
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: $black;

      flex-basis: 46%;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 1200px) {
        flex-basis: 55%;
      }

      @media only screen and (max-width: 991px) {
        flex-basis: 100%;
      }
      @media only screen and (max-width: 768px) {
        padding: 2rem 1rem 2rem 1rem;
      }
      @media only screen and (max-width: 475px) {
        border-radius: 0;
      }
      @media only screen and (max-width: 411px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0;
        font-size: 1.1rem;
        line-height: 1.5rem;
      }

      &__seconds {
        font-size: 1.25rem;
        flex-wrap: nowrap;

        @media only screen and (max-width: 475px) {
          // flex-wrap: wrap;
        }

        span {
          font-weight: bold;
          font-size: 3.125rem;

          @media only screen and (max-width: 475px) {
            font-size: 2.5rem;
          }
          @media only screen and (max-width: 411px) {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
