.home_why {
  margin-bottom: 160px;

  h2 {
    color: $purple1;
    font-size: 1.875rem;
    font-weight: bold;
    padding-bottom: 5.9375rem;
  }
  p {
    span {
      font-weight: bold;
    }
  }

  .cards-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media only screen and (max-width: 991px) {
      justify-content: space-around;
    }
  }

  .card {
    display: flex;
    justify-content: flex-end;
    flex: 1 1 18.75rem;
    border: none;
    border-radius: 2.5rem;
    background: linear-gradient(#fff 0%, #f5f5f5 100%);
    color: $purple1;
    font-size: 1.5rem;
    text-align: center;
    max-width: 18.75rem;
    min-height: 27.5rem;
    @media only screen and (max-width: 991px) {
      &:last-child {
        margin-top: 50px;
      }
    }
    @media only screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      &:not(:first-child) {
        margin-top: 50px;
      }
    }
    @media only screen and (max-width: 475px) {
      max-width: 100%;
    }

    &__body {
      padding: 0 10px 20px 10px;
    }
    p {
      padding-top: 1.25rem;
      margin-top: 0;
      margin-bottom: 0;
    }
    &__img {
    }
  }
}
