.software_usability {
  font-weight: bold;
  padding: 6.25rem 0 0 0;
  position: relative;
  margin-top: 143px;
  margin-bottom: 0;

  @media only screen and (max-width: 475px) {
    padding-top: 3.125rem;
    padding-bottom: 8.375rem;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -6.375rem;
    margin: 0 auto;
    background: no-repeat top center url('../assets/img/usability-bg.svg');
    z-index: -1;

    @media only screen and (max-width: 1200px) {
      top: -3rem;
      right: 4.5rem;
      margin: 0;
    }

    @media only screen and (max-width: 991px) {
      background: no-repeat top right url('../assets/img/usability-bg-mobile.svg');
      top: 12%;
      right: 0;
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
      top: 17%;
      background: no-repeat top right url('../assets/img/usability-bg-mobile.svg');
      background-size: 350px 350px;
    }

    @media only screen and (max-width: 560px) {
      width: 100%;
      height: 100%;
      top: 18%;
    }

    @media only screen and (max-width: 529px) {
      top: 20%;
    }

    @media only screen and (max-width: 475px) {
      width: 100%;
      height: 100%;
      top: 10%;
      background-size: 250px 250px;
    }

    @media only screen and (max-width: 375px) {
      top: 13.1%;
    }
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 43%;
    top: 0;
    background: $gray2;
    z-index: -2;

    @media only screen and (max-width: 768px) {
      height: 35%;
    }

    @media only screen and (max-width: 475px) {
      height: 23%;
    }
  }

  h1,
  h2 {
    font-weight: bold;
  }

  h1 {
    color: $pink;
    font-size: 4.375rem;
    position: relative;

    @media only screen and (max-width: 475px) {
      font-size: 2.5rem;
    }

    @media only screen and (max-width: 375px) {
      font-size: 2rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      margin-top: 0.2em;
      background: $pink;
      height: 4px;
      width: 65px;
      left: 0;
      border-radius: 2.5rem;
    }
  }

  h2 {
    font-size: 2.875rem;
    line-height: 4.375rem;

    @media only screen and (max-width: 475px) {
      font-size: 1.875rem;
      line-height: 3.125rem;
    }
  }

  &__description {
    width: 70%;
    margin-bottom: 6.25rem;

    @media only screen and (max-width: 475px) {
      width: 100%;
      margin-bottom: 13.125rem;
    }
  }

  &__description2 {
    margin: 9.375rem 0 0 0;
    @media only screen and (max-width: 475px) {
      margin: 6.25rem 0 0 0;
    }
    span {
      color: $pink;
      font-style: italic;
      font-weight: 500;
    }
  }

  .wrapper-bg {
    border-radius: 40px 40px 0px 0px;
    background: linear-gradient(#e5e3ff 0%, rgba(255, 255, 255, 0) 80%);
    backdrop-filter: blur(5px);
    max-width: 1200px;
    margin: 0 auto;

    @media only screen and (max-width: 475px) {
      margin: 0 0.75rem;
    }
  }

  .wrapper {
    padding: 3.125rem 0 0 0;

    @media only screen and (max-width: 475px) {
      font-size: 2.5rem;
    }

    .cards {
      // background: red;
      display: flex;
      justify-content: space-between;
      gap: 5.625rem;
      margin-top: 5rem;

      @media only screen and (max-width: 992px) {
        flex-wrap: wrap;
      }

      @media only screen and (max-width: 475px) {
        gap: 6.25rem;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        margin-top: 2.6em;
        background: transparent;
        border-top: 2px dashed $purple;
        height: 4px;
        width: 60%;
        right: calc(20%);
        border-radius: 2.5rem;
        z-index: -1;

        @media only screen and (max-width: 992px) {
          display: none;
        }
      }

      .card {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: transparent;
        border: none;

        &__title {
          margin: 1.875rem 0 1.25rem 0;
          font-size: 1.875rem;
          font-weight: bold;
        }

        &__description {
          font-weight: 400;
          font-size: 1.25rem;
        }
      }
    }
  }
}
