@import 'hero';
@import 'bpo';
@import 'personalization';
@import 'dispersion';
@import 'guard';
@import 'titles';

section {
  margin-top: 130px;
  @media only screen and (max-width: 475px) {
    // margin-top: 50px;
  }
}
