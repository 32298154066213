.cfdis_dispersion {
  background: $gray2;
  padding-top: 150px;
  padding-bottom: 150px;
  @media only screen and (max-width: 475px) {
    padding-top: 30px;
    padding-bottom: 50px;
    margin-bottom: 0;
  }
  section {
    @media only screen and (max-width: 475px) {
      margin-top: 100px !important;
      margin-bottom: 0;
    }
  }
  h2 {
    margin-bottom: 0;
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 2.25rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 2.25rem;
    margin-bottom: 0;
  }

  .img-desktop {
    display: block;
    margin-top: 50px;
    @media only screen and (max-width: 475px) {
      display: none;
    }
  }

  .img-mobile {
    display: none;

    @media only screen and (max-width: 475px) {
      display: block;
      margin-top: 3.5rem;
    }
  }
}
