.payroll_parameters {
  background-color: $gray2;
  padding-top: 8.125rem;

  &__border {
    border-bottom: 1px dashed gray;
    margin-top: 50px;
  }
  &__border2 {
    border-bottom: 2px solid $purple1;
    margin-top: 50px;
    margin-bottom: 50px;
    @media only screen and (max-width: 475px) {
      display: none;
    }
  }
  &__border2-mobile {
    display: none;
    @media only screen and (max-width: 475px) {
      display: block;
      border-bottom: 2px solid $purple1;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  &__implementation {
    margin-top: 50px;
    font-size: 1rem;
    color: $gray3;
    h3 {
      font-weight: bold;
      font-size: 1.25rem;
      margin-bottom: 20px;
    }
    p {
      margin: 0;
      line-height: 1.625rem;
    }
    .cards-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 50px;
      gap: 26px;
      @media only screen and (max-width: 991px) {
        justify-content: space-around;
        gap: 30px;
      }
      @media only screen and (max-width: 768px) {
        display: block;
        margin: 50px auto 0 auto;
      }
    }
    .card {
      border: 1px dashed $gray3;
      border-radius: 12px;
      background: transparent;
      color: $gray3;
      min-height: 280px;
      width: 250px;
      padding: 23px 20px;
      @media only screen and (max-width: 768px) {
        margin: 0 auto;
        &:not(:first-child) {
          margin-top: 30px;
        }
      }
      @media only screen and (max-width: 475px) {
        max-width: 100%;
      }
    }
  }
  &__anual {
    margin-top: 50px;
    font-size: 1rem;
    h3 {
      font-weight: bold;
      font-size: 1.25rem;
      margin-bottom: 20px;
    }
    p {
      margin: 0;
      line-height: 1.625rem;
    }
  }
  &__continue {
    margin-top: 50px;
    font-size: 1rem;
    h3 {
      font-weight: bold;
      font-size: 1.25rem;
      margin-bottom: 20px;
    }
    p {
      margin: 0;
      line-height: 1.625rem;
    }
    .logos {
      &:not(:last-child) {
        margin-right: 20px;
      }
      @media only screen and (max-width: 475px) {
        &:not(:last-child) {
          margin-right: 14px;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .img-gear {
      width: 100%;
      margin-top: 50px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 100px;
  }
  @media only screen and (max-width: 475px) {
    padding-top: 50px;
    padding-bottom: 100px !important;
  }
}
