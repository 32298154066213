.home_hero {
  color: $white;
  border-radius: none;
  margin-bottom: 144px;
  &__bg {
    position: absolute;
    background-image: url('../assets/img/hero-home.jpg');
    background-size: cover;
    background-position: center;
    height: 760px;
    width: 100%;
    z-index: -10;
  }

  h1 {
    font-weight: bold;
    font-size: 3.125rem;
    line-height: 3.875rem;
    margin-top: 7.4375rem;
    margin-bottom: 0.625rem;
  }
  h2 {
    font-size: 2.5rem;
    line-height: 2.25rem;
    margin-top: 0;
    margin-bottom: 0;
    span {
      text-decoration: underline;
    }
  }
  .cards-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 11.125rem;
  }
  .card {
    flex: 1 1 12.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12.5rem;
    height: 12.5rem;
    text-align: center;
    color: $purple1;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: 200ms;
    &:not(:last-child) {
      margin-right: 20px;
    }
    &:hover {
      background-color: rgba(241, 247, 250, 0.6);
      border-radius: 20px;
    }
    &__body {
      text-align: center;
      padding: 2.1875rem 0.525rem;
      h3 {
        font-size: 1.125rem;
        font-weight: bold;
        margin: 8px 0;
      }
      p {
        font-size: 0.875rem;
        margin-bottom: 0;
      }
    }
  }

  /* Para asegurar la compatibilidad de los gaps en los navegadores viejitos como Safari IOS*/
  @media only screen and (max-width: 1124px) {
    .card {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      }

      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        margin-right: 20px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .card {
      &:not(:nth-child(4)),
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      }

      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-right: 20px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .card {
      &:nth-child(2),
      &:nth-child(4) {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 475px) {
    &__bg {
      height: 1000px;
      background-image: url('../assets/img/hero-home-phone.jpg');
    }
    h1 {
      font-size: 1.9rem;
      line-height: 2.8rem;
      width: 276px;
      margin-top: 53px;
    }
    h2 {
      font-size: 1.4375rem;
      line-height: 2.25rem;
      width: 276px;
    }
    .cards-wrapper {
      margin-top: 4.375rem;
    }
    .card {
      background-color: rgba(241, 247, 250, 0.6);
      border-radius: 20px;
      width: 17.5rem;
      margin: 0 auto 1.25rem auto;

      &:last-child {
        margin: 0 auto;
      }
      &:first-child,
      &:nth-child(3) {
        margin-right: 0;
      }
      &:hover {
        background-color: $gray2;
        border-radius: 20px;
      }
      &__body {
        padding: 1.25rem 2.5rem;
      }
    }
  }
}
