.payroll_my_company {
  background: rgb(246, 248, 248);
  background: linear-gradient(
    90deg,
    rgba(246, 248, 248, 1) 0%,
    rgba(246, 248, 248, 1) 50%,
    rgba(231, 235, 235, 1) 50%,
    rgba(231, 235, 235, 1) 100%
  );
  .col-my-company {
    padding: 80px 40px;
  }
  .hand {
    position: absolute;
    left: 0;
    top: -166px;
  }
  p {
    margin: 0;
  }
  h2 {
    font-size: 1.25rem;
    font-weight: bold;
  }
  .description {
    font-size: 1rem;
    line-height: 1.625rem;
  }

  .my-company {
    background: $purple1;
    color: $pink;
    font-size: 0.875rem;
    line-height: 1.18rem;
    padding: 15px;
    border-radius: 10px;
    max-width: 255px;

    a {
      color: inherit;
    }
  }

  @media only screen and (max-width: 1620px) {
    .hand {
      width: 700px;
      left: 0;
      top: -100px;
    }
  }
  @media only screen and (max-width: 1350px) {
    .hand {
      width: 600px;
      left: 0;
      top: -50px;
    }
  }
  @media only screen and (max-width: 1160px) {
    .hand {
      width: 500px;
      left: 0;
      top: 50px;
    }
  }
  @media only screen and (max-width: 991px) {
    background: $gray4;
    .hand {
      top: -25px;
    }

    .col-my-company {
      margin-top: 370px;
    }
  }
  @media only screen and (max-width: 600px) {
    background: $gray4;
    .hand {
      top: -50px;
    }
    .kiosko-title {
      position: absolute;
      top: 50px;
      left: 10%;
      width: 115px;
      line-height: 2rem;
    }
    .col-my-company {
      margin-top: 60%;
      padding: 100px 47px 50px 48px;
    }
  }
  @media only screen and (max-width: 540px) {
    .hand {
      top: -100px;
    }
  }

  @media only screen and (max-width: 475px) {
    padding-bottom: 0 !important;
    .col-my-company {
      margin-top: 60%;
      padding: 100px 0 50px 0;
    }
  }
  @media only screen and (max-width: 420px) {
    .hand {
      top: -80px;
    }
  }
  @media only screen and (max-width: 385px) {
    .hand {
      top: -70px;
    }
  }
  @media only screen and (max-width: 360px) {
    .hand {
      top: -60px;
    }
  }
  @media only screen and (max-width: 345px) {
    .hand {
      top: -50px;
    }
  }
  @media only screen and (max-width: 330px) {
    .hand {
      top: -40px;
    }
  }
}
