@import 'calculation';
@import 'communication';
@import 'hero';
@import 'incidents';
@import 'interaction';
@import 'parameters';
@import 'report-generator';
@import 'retrospective';
@import 'services';
@import 'special-processes';
@import 'titles';
@import 'my-company';

.sections-payroll {
  section {
    padding-bottom: 9.375rem;
    margin-bottom: 0;
    margin-top: 0;
    @media only screen and (max-width: 475px) {
      padding-bottom: 100px;
    }
  }
}
