.contact_privacy {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 3.125rem;
  margin-bottom: 8.125rem;
  text-align: left;

  li {
    margin-top: 2rem;
    margin-bottom: 2rem;

    span {
      font-weight: normal;
      font-style: italic;
    }
  }

  h2 {
    font-size: 3.125rem;
    font-weight: bold;
    line-height: 3.375rem;
    margin-bottom: 4.625rem;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      margin-top: 1.25rem;
      background: linear-gradient(to right, #aa20ff 0%, #ff14c1 100%);
      height: 4px;
      width: 65px;
      border-radius: 2.5rem;
      left: 0;
    }
    @media only screen and (max-width: 375px) {
      font-size: 1.9375rem;
      line-height: 2.875rem;
    }
  }

  .date {
    font-size: 1.125rem;
    font-weight: bold;
  }

  span {
    font-weight: bold;
  }

  .question {
    margin-top: 6.25rem;

    &--title {
      font-size: 1.875rem;
      font-weight: bold;
    }
  }
}
