.cfdis_bpo {
  z-index: 1000;
  position: relative;
  p:first-child {
    color: $purple1;
    margin-bottom: 12.375rem;
    font-weight: bold;
    line-height: 36px;
    font-size: 1.25rem;
    @media only screen and (max-width: 475px) {
      text-align: center;
      margin-bottom: 150px;
    }
  }
  .gap-5 {
    column-gap: 60px !important;
    @media only screen and (max-width: 1124px) {
      row-gap: 120px !important;
    }
  }
  .card {
    position: relative;
    border: none;
    border-radius: 40px 40px 40px 100px;
    background: linear-gradient(#fff 0%, #f1f7fa 100%);
    text-align: center;
    padding: 20px;
    min-width: 280px;
    min-height: 420px;
    font-size: 1.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media only screen and (max-width: 390px) {
      min-width: unset;
      min-height: 450px;
    }
    @media only screen and (max-width: 350px) {
      min-height: 510px;
    }

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: -50px;
      margin-left: auto;
      margin-right: auto;
      width: 120px;
    }
    h3 {
      margin-top: 70px;
      margin-bottom: 20px;
      font-size: inherit;
      font-weight: bold;
    }
    p:first-child {
      margin-bottom: 34px;
    }
    a {
      color: inherit;
      cursor: pointer;
      height: 23px;
      min-width: 96px;
      position: absolute;
      margin: 0 auto 2rem auto;
      bottom: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-end;
    }
  }

  .col:not(:last-child) {
    @media only screen and (max-width: 768px) {
      margin-bottom: 120px;
    }
    @media only screen and (max-width: 475px) {
      margin-bottom: 50px;
    }
  }
}
