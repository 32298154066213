.software_ui {
  margin-top: 0;
  background: $gray6;
  position: relative;
  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media only screen and (max-width: 475px) {
    overflow-x: unset;
    overflow-y: unset;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 40%;
    bottom: -2.5%;
    right: 17%;
    background: no-repeat bottom right url('../assets/ui/illust-ui.svg');
    z-index: 2;

    @media only screen and (max-width: 1200px) {
      bottom: -2.5%;
      right: 10%;
    }
    @media only screen and (max-width: 1124px) {
      bottom: -2.3%;
    }
    @media only screen and (max-width: 991px) {
      bottom: -1.75%;
    }
    @media only screen and (max-width: 768px) {
      bottom: -1.675%;
      right: -5%;
    }
    @media only screen and (max-width: 768px) {
      right: -15%;
      bottom: -1.875%;
    }
    @media only screen and (max-width: 620px) {
      right: -25%;
    }
    @media only screen and (max-width: 595px) {
      right: -35%;
    }
    @media only screen and (max-width: 501px) {
      right: -42%;
    }
    @media only screen and (max-width: 475px) {
      right: 0;
      bottom: -1.2%;
      background: no-repeat bottom center url('../assets/ui/illust-ui.svg');
    }
    @media only screen and (max-width: 436px) {
      bottom: -1.1%;
    }
    @media only screen and (max-width: 370px) {
      bottom: -1%;
    }
    @media only screen and (max-width: 340px) {
      bottom: -0.95%;
    }
  }

  .container {
    padding-top: 8.125rem;
    padding-bottom: 17.1875rem;
    @media only screen and (max-width: 475px) {
      padding-bottom: 24.5625rem;
    }
  }

  ul,
  ol,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-left: 0.875rem;
  }

  .margin {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .cards {
    display: grid;
    grid-gap: 5.625rem;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    justify-items: center;
    text-align: center;

    @media only screen and (max-width: 1024px) {
      grid-gap: 1.5rem;
    }

    @media only screen and (max-width: 991px) {
      grid-gap: 1.2rem;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
    }

    @media only screen and (max-width: 475px) {
      grid-gap: 3.125rem;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }

    .card {
      border: none;
      font-size: 1rem;
      height: 100%;
      background: none;

      .card-header {
        color: $white;
        border-radius: 1.25rem 1.25rem 0 0;

        &__icon {
          padding: 35px 0 20px 0;
        }

        &__title {
          font-weight: bold;
          font-size: 1.25rem;
          padding-bottom: 3.5rem;
        }
      }
      .card-body {
        color: $purple;
        background: linear-gradient(#fff 0%, #f1f7fa 100%);
        box-shadow: 0px 3px 35px rgba(0, 0, 0, 0.16);
        border-radius: 1.25rem;
        margin-top: -2.25rem;
        padding: 1.875rem 1.25rem;
        text-align: left;
        z-index: 3;
        line-height: 1.625rem;
        font-size: 1rem;
      }
    }
  }
}
