.integrity_full {
  overflow: hidden;
  position: relative;
}

.integrity_hero {
  overflow: hidden;
  @media only screen and (max-width: 476px) {
    height: 600px;
  }

  .hero-text {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    @media only screen and (max-width: 991px) {
      margin-top: 45px;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 50px;
    }
    @media only screen and (max-width: 476px) {
      margin-top: 30px;
    }
    h1 {
      font-size: 2.875rem;
      font-weight: 300;
      line-height: 3.75rem;
      color: $white;
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 768px) {
        width: 85%;
      }
      @media only screen and (max-width: 476px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
        width: 65%;
      }
      @media only screen and (max-width: 411px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
        width: 85%;
      }
    }
    .sub-title {
      font-size: 3.125rem;
      font-weight: bold;
      margin-bottom: 571px;
      @media only screen and (max-width: 476px) {
        font-size: 1.5rem;
      }
    }
  }
}

.bg {
  z-index: 2;
  max-height: 554px;
  max-width: 1320px;
  inset: 0;
  position: absolute;
  margin: 21rem auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media only screen and (max-width: 768px) {
    display: none;
  }

  &-img {
    z-index: -2;
    position: absolute;
    margin: 0 auto;
    inset: 0;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}
