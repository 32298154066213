.contact_hero {
  margin-top: 50px;
  margin-bottom: 74px;
  h2 {
    font-size: 3.125rem;
    font-weight: bold;
    line-height: 3.375rem;
    margin-bottom: 4.625rem;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      margin-top: 1.25rem;
      background: linear-gradient(to right, #aa20ff 0%, #ff14c1 100%);
      height: 4px;
      width: 65px;
      border-radius: 2.5rem;
      left: 0;
    }
    @media only screen and (max-width: 375px) {
      font-size: 1.9375rem;
      line-height: 2.875rem;
    }
  }
}
