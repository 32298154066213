.the-company_our-history {
  color: $purple;
  font-size: 1.25rem;
  margin-top: 50px;

  h2 {
    font-weight: bold;
    font-size: 1.875rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .gap-2 {
    grid-gap: 2.25rem !important;
    gap: 2.25rem !important;
  }

  &__text {
    padding-top: 3.125rem;
    line-height: 36px;
    font-size: 1.25rem;
  }
}
