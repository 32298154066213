.my_company_users {
  border: 1px solid transparent;

  background-image: url('../assets/img/users.png');
  background-size: cover;
  background-position: center;
  &__wrapper {
    background: rgb(229, 227, 255);
    background: rgb(229, 227, 255);
    background: linear-gradient(180deg, rgba(229, 227, 255, 0.5046393557422969) 0%, rgba(255, 255, 255, 1) 100%);
    border-radius: 40px 40px;
    backdrop-filter: blur(5px);
    max-width: 1200px;
    min-width: 300;
    margin: 650px auto 0 auto;
    padding: 60px 50px;
  }

  &__title {
    font-size: 3.125rem;
    font-weight: bold;
    line-height: 3.75rem;
    margin-bottom: 120px;
    position: relative;
    @media only screen and (max-width: 475px) {
      font-size: 30px;
      line-height: 50px;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      margin-top: 2.5rem;
      background: linear-gradient(to right, #aa20ff 0%, #ff14c1 100%);
      height: 4px;
      width: 65px;
      border-radius: 2.5rem;
      left: 0;
    }
  }
  &__icon-quote {
    margin-bottom: 50px;
  }
  &__comment {
    font-size: 1.5rem;
    line-height: 2.75rem;
    margin-bottom: 84px;
  }
  &__line {
    width: 100%;
    border: 1px solid $purple3;
    margin-bottom: 50px;
  }
  &__person {
    margin-bottom: 80px;
    &--name {
      font-size: 1.875rem;
      font-weight: bold;
      line-height: 2.875rem;
    }
    &--position {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }

  @media only screen and (max-width: 992px) {
    padding-top: 20px;
    background-image: url('../assets/img/users-phone.png');
    background-size: auto;
    background-position: top center;
    background-repeat: no-repeat;
    &__wrapper {
      margin: 200px 12px 0;
      padding: 50px 20px;
    }
  }
}
