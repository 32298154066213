.contact_forms {
  margin-top: 3.125rem;
  margin-bottom: 8.125rem;

  h2 {
    font-size: 3.125rem;
    font-weight: bold;
    line-height: 3.375rem;
    margin-bottom: 4.625rem;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      margin-top: 2.5rem;
      background: linear-gradient(to right, #aa20ff 0%, #ff14c1 100%);
      height: 4px;
      width: 65px;
      border-radius: 2.5rem;
      left: 0;
    }
    @media only screen and (max-width: 375px) {
      font-size: 1.9375rem;
      line-height: 2.875rem;
    }
  }

  h3 {
    font-size: 1.875rem;
    line-height: 2.875rem;
    margin-bottom: 6.875rem;
  }

  .card {
    position: relative;
    border: none;
    border-radius: 40px 40px 40px 100px;
    background: linear-gradient(#fff 0%, #f1f7fa 100%);
    text-align: center;
    padding: 0px 40px 6.375rem 40px;
    min-width: 280px;
    margin-bottom: 50px;
    @media only screen and (max-width: 380px) {
      min-width: unset;
      padding: 0px 20px 6.375rem 20px;
    }
    @media only screen and (max-width: 380px) {
      padding: 0px 10px 6.375rem 10px;
    }
    .icon-card {
      position: absolute;
      left: 0;
      right: 0;
      top: -50px;
      margin-left: auto;
      margin-right: auto;
      width: 120px;
    }
    h4 {
      margin-top: 70px;
      margin-bottom: 2.1875rem;
      font-size: 1.375rem;
    }

    .button-nomi {
      .btn-comp {
        padding: 0.525em 2em;
        @media only screen and (max-width: 475px) {
          padding: 0 0.125em;
        }
      }
      @media only screen and (max-width: 475px) {
        display: block;
      }
    }
  }

  .gap-5 {
    row-gap: 60px !important;
  }

  .info-general {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3.125rem;
    @media only screen and (max-width: 576px) {
      margin-top: 9.375rem;
    }
  }

  p {
    max-width: 36%;
    font-size: 1.25rem;
    line-height: 2.25rem;
    margin-bottom: 0;
    @media only screen and (max-width: 1200px) {
      max-width: 50%;
    }
    @media only screen and (max-width: 991px) {
      max-width: 70%;
    }
    @media only screen and (max-width: 576px) {
      max-width: 85%;
    }
    @media only screen and (max-width: 475px) {
      max-width: 100%;
    }
  }
  a {
    font-size: 1.25rem;
    font-weight: bold;
    color: $blue2;
  }
}
