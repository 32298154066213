.integrity_users {
  position: relative;
  margin-top: 150px;
  max-height: 1885px;
  padding-top: 735px;

  &__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    width: 100%;
    max-width: 2133px;
    height: 100%;
    background-image: url('../assets/img/users.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  &__wrapper {
    background: linear-gradient(180deg, rgba(229, 227, 255, 0.5046393557422969) 0%, rgba(255, 255, 255, 1) 100%);
    border-radius: 40px 40px;
    backdrop-filter: blur(5px);
    max-width: 1200px;
    min-width: 300px;
    margin: 0 auto 0 auto;
    padding: 60px 50px;
  }

  &__title {
    font-size: 3.125rem;
    font-weight: bold;
    line-height: 3.75rem;
    margin-bottom: 120px;
    position: relative;
    @media only screen and (max-width: 475px) {
      font-size: 30px;
      line-height: 50px;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      margin-top: 2.5rem;
      background: linear-gradient(to right, #aa20ff 0%, #ff14c1 100%);
      height: 4px;
      width: 65px;
      border-radius: 2.5rem;
      left: 0;
    }
  }
  &__icon-quote {
    margin-bottom: 50px;
  }
  &__comment {
    font-size: 1.5rem;
    line-height: 2.75rem;
    margin-bottom: 84px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      margin-top: -4.5rem;
      background: url(../assets/img/quote-left.svg);
      height: 32px;
      width: 36px;
      left: 0;
    }
  }
  &__line {
    width: 100%;
    border: 1px solid $purple3;
    margin-bottom: 50px;
  }
  &__person {
    margin-bottom: 80px;
    &--name {
      font-size: 1.875rem;
      font-weight: bold;
      line-height: 2.875rem;
    }
    &--position {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }
  &__circles {
    margin-bottom: 178px;
    display: flex;
    justify-content: center;
    gap: 10px;
    .active {
      background-color: $purple3;
    }
    &--carrucel {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid $purple3;
    }
  }

  @media only screen and (max-width: 992px) {
    padding-top: 20px;
    background-image: url('../assets/img/users-phone.png');
    background-size: auto;
    background-position: top center;
    background-repeat: no-repeat;
    &__wrapper {
      margin: 200px 12px 0;
      padding: 50px 20px;
    }
  }
}
