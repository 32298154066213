.icon_rotate_clic {
  cursor: pointer;
  .rotate-prueba {
    transition: transform 300ms ease-in-out;
  }
  .active {
    transform: rotate(-180deg);
  }
}

.icon_rotate_hover {
  .rotate-prueba {
    transition: transform 300ms ease-in-out;
  }
}
