.dicoma_welcome {
  margin-bottom: 0;

  h2 {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }

  p {
    font-size: 1.25rem;
    line-height: 2.25rem;
    font-weight: bold;
    margin-bottom: 50px;
  }
}
